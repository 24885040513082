<template>
  <div>
    <b-modal
      id="modal-login"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      title=""
      size="sm"
    >
      <b-row align-h="center">
        <b-spinner
          class="m-5"
          style="width: 4rem; height: 4rem"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
    </b-modal>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility1"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility1 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col class="d-flex align-items-center mb-1" md="12">
            <b-form-checkbox class="mr-1" v-model="settings.sameDate" />
            <h6 class="mb-0">Date of admission same as date of registration</h6>
          </b-col>
          <b-col class="d-flex align-items-center mb-1" md="12">
            <b-form-checkbox class="mr-1" v-model="settings.sameClass" />
            <h6 class="mb-0">Current class same as class admitted</h6>
          </b-col>
          <b-col class="d-flex align-items-center mb-1" md="12">
            <b-form-checkbox class="mr-1" v-model="settings.sameSec" />
            <h6 class="mb-0">Current section same as section admitted</h6>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              @click="saveSettings()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Search Student</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col>
            <b-input-group>
              <b-input-group-prepend
                is-text
                @click="loadSearch()"
                style="cursor: pointer"
              >
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="searchQuery"
                @keyup.enter="loadSearch()"
                placeholder="Search..."
              >
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-form>

      <b-table
        class="mt-1"
        show-empty
        :busy="studentLoading"
        ref="selectableTable"
        :items="studentData"
        :fields="fields"
        responsive
        hover
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(details)="data">
          <p>
            <b><span>Name: </span> </b>{{ data.item.name }}
          </p>
          <p>
            <b><span>Father: </span></b> {{ data.item.father_name }}
          </p>
          <p>
            <b><span>Class: </span></b> {{ data.item.meta_class }}
          </p>
        </template>
      </b-table>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="sessionbar"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Sessions</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="sessionbar = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group
              label="Name"
              invalid-feedback="Session name is required."
            >
              <b-form-input
                ref="sess_name"
                v-model="sessionObj.session"
                placeholder="Enter session name here"
                @focusout="SessName()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="saveSession()"
              :disabled="savingacc"
            >
              <b-spinner v-if="savingacc" small type="grow" />
              <feather-icon v-else :icon="accIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="allSess"
          :fields="sessFields"
          responsive
          hover
          small
        >
          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(session)="data">
            <b-badge variant="light-primary" v-if="data.item.isCurrentSession">
              {{ data.item.session }}
            </b-badge>
            <span v-else>{{ data.item.session }}</span>
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editSess(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteSess(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>

              <b-button
                v-if="!data.item.isCurrentSession"
                variant="outline-primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="setCurrentSess(data.item.id)"
              >
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
      width="500px"
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Manage Discount References</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="6">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="d_name"
            >
              <b-form-input
                v-model.trim="disObj.name"
                placeholder="Enter name here"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Type" invalid-feedback="Type is required.">
              <v-select
                v-model="disObj.discountType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="disTypes"
                :clearable="false"
                :reduce="(val) => val.value"
                label="text"
                placeholder="Select type"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Calculate By"
              invalid-feedback="Calculate By is required."
            >
              <v-select
                v-model="disObj.calculatedBy"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="disOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                label="text"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="disObj.calculatedBy == 'amount'">
            <b-form-group
              label="Amount"
              invalid-feedback="Amount is required."
              ref="d_amount"
            >
              <b-form-input
                type="number"
                placeholder="Enter amount"
                v-model="disObj.amount"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="disObj.calculatedBy == 'percentage'">
            <b-form-group
              label="Percentage"
              invalid-feedback="Percentage is required."
              ref="d_perc"
            >
              <b-form-input
                type="number"
                placeholder="Enter percentage"
                v-model="disObj.percentage"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              variant="success"
              class="mt-1"
              block
              @click="saveDis()"
              :disabled="savingDis"
            >
              <b-spinner v-if="savingDis" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-2"
          show-empty
          :items="disData"
          :fields="disFields"
          responsive
          hover
          small
        >
          <template #head(actions)="data">
            <div class="text-center">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editDis(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <!-- <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteDis(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button> -->
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Discount Setting</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
              >Discount</label
            >
            <b-form-checkbox v-model="dCopy.valueBool" switch />
          </b-col>
          <b-col md="12" class="mt-1">
            <b-button
              variant="success"
              :disabled="savingSett"
              block
              @click="saveDSett()"
            >
              <b-spinner v-if="savingSett" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <form-wizard
      ref="wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="steps-transparent mb-1"
      @on-complete="shortcutSubmit"
    >
      <!--  :before-change="validationForm"-->
      <tab-content title="Student" icon="feather icon-file-text">
        <b-row class="match-height">
          <b-col md="6">
            <b-card class="" body-class="position-relative">
              <b-row align-h="center">
                <!-- <b-col cols="4"> </b-col> -->
                <b-col class="ml-3" cols="4">
                  <b-form-group
                    class="text-center"
                    ref="picture"
                    :state="nameState"
                    label=" "
                    label-for="name-input"
                    invalid-feedback="Picture is required."
                  >
                    <b-media no-body ref="picture">
                      <div v-if="logoloading == true">
                        <b-spinner
                          variant="primary"
                          type="grow"
                          class="m-5"
                          label="Spinning"
                        ></b-spinner>
                      </div>

                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                            @click="$refs.fileProfile.click()"
                            v-if="logoloading == false"
                            style="border-radius: 100px"
                            fluid
                            height="120px"
                            width="120px"
                          />

                          <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
                        </b-link>

                        <b-link v-if="logoloading == 'loaded'">
                          <div class="text-right" style="margin-right: 35px">
                            <b-button
                              @click="deleteLogo()"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              class="position-absolute btn-icon"
                              variant="danger"
                            >
                              <feather-icon icon="Trash2Icon" />
                            </b-button>
                          </div>

                          <b-img
                            class="rcorners2"
                            :src="plus"
                            height="120px"
                            width="120px"
                          />

                          <br />
                        </b-link>
                        <input
                          type="file"
                          id="fileProfile"
                          hidden
                          ref="fileProfile"
                          @change="selectlogo()"
                          accept="image/*"
                        />
                      </b-media-aside>
                    </b-media>
                    <small class="mr-1 text-danger" v-if="logoerror"
                      >Logo is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                style="position: absolute; right: 10px; top: 10px"
                class="d-flex align-items-center"
              >
                <b-form-checkbox
                  v-if="selected === 'add'"
                  v-model="addingMsg"
                  switch
                  @input="setMsg()"
                  v-b-tooltip.hover.top
                  title="Admission Message"
                />
                <feather-icon
                  v-if="selected === 'add' || selected === 'reg'"
                  icon="SettingsIcon"
                  size="28"
                  @click="visibility1 = true"
                  class="cursor-pointer ml-50"
                  v-b-tooltip.hover.top
                  title="Settings"
                />
              </div>
              <!-- <h3 class="mt-1">Student Information</h3> -->
              <hr />

              <b-row>
                <!-- v-if="
                    reportDomain == 'myskoolhpgs'
                  " -->
                <!-- <b-col md="6">
                  <b-form-group
                    label="Campus *"
                    invalid-feedback="Campus is required."
                    ref="camp"
                  >
                    <v-select
                      v-model="myObj.campusID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="campuses"
                      :clearable="false"
                      :reduce="(val) => val.id"
                      label="branch"
                      placeholder="Select campus"
                      @input="CheckCampus()"
                    />
                  </b-form-group>
                </b-col> -->
                <b-col md="6">
                  <b-form-group
                    label="Name *"
                    invalid-feedback="Student name is required."
                  >
                    <b-form-input
                      placeholder="Enter name."
                      ref="name"
                      v-model.trim="myObj.name"
                      @focusout="CheckName()"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Father name *"
                    invalid-feedback="Father name is required."
                  >
                    <b-form-input
                      ref="fname"
                      placeholder="Enter father name."
                      v-model.trim="myObj.father_name"
                      @focusout="CheckFName()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Gender"
                    invalid-feedback="School name is required."
                  >
                    <v-select
                      v-model="myObj.gender"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genders"
                      :clearable="false"
                      input-id="categoryId"
                      ref="gender"
                      :disabled="dataloading"
                      placeholder="Select gender"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Date of Birth"
                    invalid-feedback="date of birth is required."
                    ref="dob"
                  >
                    <flat-pickr
                      ref="dob"
                      :config="config"
                      v-model="myObj.date_of_birth"
                      class="form-control"
                      placeholder="Select date of birth."
                      @on-change="formatDate()"
                    />
                    <!-- @on-change="CheckDob()" -->
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="`${
                      otherObj && otherObj.valueBool ? 'Caste' : 'Surname'
                    }`"
                    :invalid-feedback="`${
                      otherObj && otherObj.valueBool ? 'Caste' : 'Surname'
                    } is required.`"
                  >
                    <b-form-input
                      :placeholder="`Enter ${
                        otherObj && otherObj.valueBool ? 'Caste' : 'Surname'
                      }`"
                      v-model="myObj.surname"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Religion"
                    invalid-feedback="School name is required."
                  >
                    <v-select
                      v-model="myObj.religion"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="religions"
                      :clearable="false"
                      input-id="religionId"
                      ref="religion"
                      :disabled="dataloading"
                      placeholder="Select religion"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- <b-col md="6">
                  <b-form-group
                    label="Contact"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter contact number."
                      v-model="myObj.contact_student"
                    />
                  </b-form-group>
                </b-col> -->

                <b-col md="12">
                  <b-form-group
                    label="Last Institute"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter last institute."
                      v-model.trim="myObj.last_institute"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Date of birth in words."
                    invalid-feedback="Date of birth in words is required."
                  >
                    <b-form-input
                      placeholder="Enter date of birth in words."
                      v-model="myObj.date_of_birth_words"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Place of birth"
                    invalid-feedback="Place of birth is required."
                  >
                    <b-form-input
                      placeholder="Enter place of birth"
                      v-model.trim="myObj.place_of_birth"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Mother Tongue"
                    invalid-feedback="Mother Tongue is required."
                  >
                    <b-form-input
                      placeholder="Enter mother tongue"
                      v-model.trim="myObj.mother_tongue"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Nationality"
                    invalid-feedback="Nationality is required."
                  >
                    <b-form-input
                      placeholder="Enter nationality"
                      v-model.trim="myObj.nationality"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Area"
                    invalid-feedback="Area is required."
                  >
                    <v-select
                      v-model="myObj.areaID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="areaOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(opt) => opt.id"
                      placeholder="Select area"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Current Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter current address."
                      v-model="myObj.address_current"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Permanent Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter permanent address."
                      v-model="myObj.address_permanent"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Physical Handicap"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter physical handicap."
                      v-model="myObj.physical_handicap"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col md="6">
            <b-card class="">
              <h3 class="d-flex align-items-center justify-content-between">
                Registration Information
                <feather-icon
                  size="22"
                  icon="SettingsIcon"
                  class="cursor-pointer"
                  @click="openDSett()"
                  v-b-tooltip.hover.top
                  title="Settings"
                />
              </h3>
              <hr />
              <b-row>
                <b-col md="6" v-if="schGr && schGr.valueBool">
                  <b-form-group
                    :label="`${schGr.value}`"
                    :invalid-feedback="`${schGr.value} is required.`"
                  >
                    <b-form-input
                      :placeholder="`Enter ${schGr.value}`"
                      v-model="myObj.grNoLocal"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="spNo && spNo.valueBool">
                  <b-form-group
                    :label="`${spNo.value}`"
                    :invalid-feedback="`${spNo.value} is required.`"
                  >
                    <b-form-input
                      :placeholder="`Enter ${spNo.value}`"
                      v-model="myObj.receiptNo"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  :md="
                    $store.state.currentBranch.organizationType == 'coaching'
                      ? '4'
                      : '6'
                  "
                >
                  <b-form-group
                    style="position: relative"
                    label="Family code"
                    invalid-feedback="Family code is required."
                  >
                    <feather-icon
                      v-if="selected == 'add'"
                      class="cursor-pointer"
                      style="position: absolute; right: 0; top: -25px"
                      icon="SearchIcon"
                      size="18"
                      @click="showSidebar()"
                    />
                    <b-form-input
                      type="number"
                      v-model="myObj.family_code"
                      class="form-control"
                      placeholder="Enter family code"
                      @keyup.enter="searchByFamily()"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  :md="
                    $store.state.currentBranch.organizationType == 'coaching'
                      ? '4'
                      : '6'
                  "
                >
                  <b-form-group
                    :label="
                      reportDomain == 'myskoolhpgs' ||
                      reportDomain == 'myskoolprofectus'
                        ? 'CID'
                        : $store.state.userData.studentStatus == 'present'
                        ? 'Gr number *'
                        : 'Reg number *'
                    "
                    :invalid-feedback="
                      reportDomain == 'myskoolhpgs' ||
                      reportDomain == 'myskoolprofectus'
                        ? 'CID'
                        : $store.state.userData.studentStatus == 'present'
                        ? 'Gr number is required'
                        : 'Reg number is required'
                    "
                  >
                    <b-form-input
                      v-model="myObj.grNo"
                      class="form-control"
                      :placeholder="
                        reportDomain == 'myskoolhpgs' ||
                        reportDomain == 'myskoolprofectus'
                          ? 'Enter CID'
                          : $store.state.userData.studentStatus == 'present'
                          ? 'Enter Gr number '
                          : 'Enter reg number'
                      "
                      @focusout="CheckGrNo()"
                      ref="grNo"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="4"
                  v-if="
                    this.$store.state.currentBranch.organizationType ==
                    'coaching'
                  "
                >
                  <b-form-group invalid-feedback="Session is required.">
                    <div class="d-flex justify-content-between">
                      <label class="bv-no-focus-ring col-form-label pt-0"
                        >Session</label
                      >
                      <feather-icon
                        class="cursor-pointer"
                        icon="SettingsIcon"
                        size="18"
                        v-b-tooltip.hover.top
                        title="Sessions"
                        @click="openSession()"
                      />
                    </div>
                    <v-select
                      ref="session"
                      v-model="myObj.sessionID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="allSess"
                      :reduce="(val) => val.id"
                      label="session"
                      :clearable="false"
                      placeholder="Select session"
                    />
                  </b-form-group>
                </b-col>
                <!-- <b-col md="4" >
                  <b-form-group
                    label="Student Category"
                    invalid-feedback="Student category is required."
                  >
                    <v-select
                      ref="category"
                      v-model="myObj.student_category"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categories"
                      :clearable="false"
                      @change="checkCategory()"
                      :disabled="dataloading"
                      placeholder="Select category"
                    />
                  </b-form-group>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col
                  :md="
                    ['left', 'alumni'].includes(myObj.status.toLowerCase())
                      ? '4'
                      : '6'
                  "
                >
                  <b-form-group
                    label="Date of registration"
                    invalid-feedback="School name is required."
                  >
                    <flat-pickr
                      :config="config"
                      v-model="myObj.date_of_registraion"
                      class="form-control"
                      placeholder="Select date of registration."
                      @input="setSameFields('date')"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  :md="
                    ['left', 'alumni'].includes(myObj.status.toLowerCase())
                      ? '4'
                      : '6'
                  "
                >
                  <b-form-group
                    label="Date of Admission"
                    invalid-feedback="School name is required."
                  >
                    <flat-pickr
                      :config="config"
                      v-model="myObj.date_of_admission"
                      class="form-control"
                      placeholder="Select date of admission."
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="4"
                  v-if="['left', 'alumni'].includes(myObj.status.toLowerCase())"
                >
                  <b-form-group label="Leaving Date" invalid-feedback="">
                    <flat-pickr
                      :config="config"
                      v-model="myObj.date_of_leaving"
                      class="form-control"
                      placeholder="Select date of leaving."
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="B-Form"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter B-Form."
                      v-model="myObj.bform_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Monthly fees *"
                    invalid-feedback="Monthly fees is required."
                  >
                    <div class="d-flex">
                      <b-form-input
                        :disabled="uRights.hideFee"
                        placeholder="currency"
                        ref="curr"
                        v-model="myObj.currency"
                        style="flex: 1"
                        class="mr-50"
                      />

                      <b-form-input
                        :disabled="uRights.hideFee"
                        style="flex: 4"
                        placeholder="Enter monthly fee."
                        ref="monthly_fee"
                        type="number"
                        v-model="myObj.monthly_fee"
                        @keyup="CheckFees()"
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="4">
                  <b-form-group
                    label="Discount"
                    invalid-feedback="Discount percentage is required."
                  >
                    <b-form-input
                      placeholder="Enter discount percentage."
                      type="number"
                      ref="discount_percentage"
                      @keyup="CalculateDiscount()"
                      @focusout="CheckDiscount()"
                      v-model="myObj.discount_percentage"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Net amount"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="netAmount"
                      min="0"
                      :disabled="true"
                      max="100"
                    />
                  </b-form-group>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    invalid-feedback="Challan due date is required."
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="bv-no-focus-ring col-form-label pt-0"
                        >Challan due date</label
                      >
                      <b-badge
                        variant="primary"
                        class="mb-50 cursor-pointer"
                        @click="myObj.challan_due_date = null"
                        >Clear</b-badge
                      >
                    </div>
                    <flat-pickr
                      :config="config"
                      v-model="myObj.challan_due_date"
                      class="form-control"
                      placeholder="Select challan due date."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Timely discount"
                    invalid-feedback="Discount percentage is required."
                  >
                    <b-form-input
                      placeholder="Enter timely discount."
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Class (admitted) *"
                    invalid-feedback="Class at the time of admission is required."
                    ref="class_admitted"
                  >
                    <v-select
                      v-model="myObj.class_admitted"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="classesOptions"
                      :clearable="false"
                      :reduce="(val) => val.id"
                      input-id="id"
                      label="name"
                      ref="class_admitted"
                      @input="FillSections()"
                      @search:blur="CheckClassAdmit()"
                      :disabled="dataloading"
                      placeholder="Select class admitted."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Section (admitted) *"
                    invalid-feedback="Admission section name is required."
                    ref="section_admitted"
                  >
                    <v-select
                      v-model="myObj.section_admitted"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="admittedSections"
                      :reduce="(val) => val.id"
                      label="section"
                      :clearable="false"
                      :disabled="dataloading"
                      placeholder="Select section admitted."
                      @input="setSameFields('section')"
                      @search:blur="CheckAdmitSec()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Class (current) *"
                    invalid-feedback="Current class is required."
                    ref="class_current"
                  >
                    <v-select
                      v-model="myObj.class_current"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="classesOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(val) => val.id"
                      input-id="id"
                      ref="class_current"
                      @input="FillSectionsCurrent('select')"
                      @search:blur="CheckClassCurrent()"
                      :disabled="dataloading"
                      placeholder="Select current class."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Section (current) *"
                    invalid-feedback="Current section name is required."
                    ref="section_current"
                  >
                    <v-select
                      v-model="myObj.section_current"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="currentSections"
                      :reduce="(val) => val.id"
                      label="section"
                      :clearable="false"
                      :disabled="dataloading"
                      @search:blur="CheckCurrentSec()"
                      placeholder="Select current section."
                    />
                  </b-form-group>
                </b-col>
                <template v-if="disSetting && disSetting.valueBool">
                  <b-col md="12">
                    <b-form-group
                      invalid-feedback="Discount Reference is required."
                      ref="d_ref"
                    >
                      <div class="d-flex">
                        <label class="bv-no-focus-ring col-form-label pt-0"
                          >Discount Reference</label
                        >
                        <feather-icon
                          class="cursor-pointer ml-50"
                          icon="SettingsIcon"
                          size="18"
                          v-b-tooltip.hover.top
                          title="Mange References"
                          @click="openDis()"
                        />
                      </div>
                      <v-select
                        v-model="myObj.discountReferenceID"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="[{ id: 0, name: 'None' }, ...disData]"
                        :reduce="(val) => val.id"
                        label="name"
                        :clearable="false"
                        placeholder="Select discount reference"
                        @input="setDiscount()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Class Fee"
                      invalid-feedback="Class Fee is required."
                    >
                      <b-form-input
                        type="number"
                        v-model="myObj.classFee"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      invalid-feedback="Discount is required."
                      ref="dis"
                    >
                      <div class="d-flex">
                        <label class="bv-no-focus-ring col-form-label pt-0"
                          >Discount</label
                        >
                        <feather-icon
                          class="cursor-pointer ml-50"
                          icon="RefreshCwIcon"
                          size="18"
                          v-b-tooltip.hover.top
                          title="Calculate Monthly Fee"
                          @click="setFee()"
                        />
                      </div>
                      <b-form-input
                        type="number"
                        placeholder="Enter discount"
                        v-model="myObj.discount"
                        :disabled="disableDiscount"
                        @keyup="checkDis()"
                        @keyup.enter="setFee()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Discount Note"
                      invalid-feedback="Note is required."
                    >
                      <b-form-textarea
                        placeholder="Enter discount note"
                        rows="2"
                        v-model="myObj.discountNote"
                      />
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Roll No"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      type="number"
                      placeholder="Enter roll number"
                      v-model="myObj.roll_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="House"
                    invalid-feedback="Surname is required."
                  >
                    <b-form-input
                      placeholder="Enter house"
                      v-model="myObj.house"
                    />
                  </b-form-group>
                </b-col>

                <!-- <b-col md="6">
                  <b-form-group
                    label="Enrolment No"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input placeholder="Enter enrolment number" />
                  </b-form-group>
                </b-col> -->
              </b-row>

              <b-row>
                <!-- <b-col md="6">
                  <b-form-group
                    label="Ref Id no"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter ref id no."
                      v-model="myObj.rfid_number"
                    />
                  </b-form-group>
                </b-col> -->
                <b-col md="6">
                  <b-form-group
                    label="Re-admission date"
                    invalid-feedback="School name is required."
                  >
                    <flat-pickr
                      :config="config"
                      v-model="myObj.date_of_readmission"
                      class="form-control"
                      placeholder="Select date of admission."
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Last class"
                    invalid-feedback="Last class is required."
                  >
                    <b-form-input
                      placeholder="Enter last class."
                      v-model="myObj.last_class"
                    />
                  </b-form-group>
                </b-col>

                <!-- <b-col md="6">
                  <b-form-group
                    label="Tuitor number"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input placeholder="Enter tuitor number" />
                  </b-form-group>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Last Session"
                    invalid-feedback="Monthly fees is required."
                  >
                    <b-form-input
                      placeholder="Enter last session."
                      v-model="myObj.last_session"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Last Result"
                    invalid-feedback="Monthly fees is required."
                  >
                    <b-form-input
                      placeholder="Enter last result."
                      v-model="myObj.last_result"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="match-height" align-h="center" v-if="false">
          <b-col md="8">
            <b-card class="">
              <h3 class="mt-1">Admission Progress</h3>
              <hr />
              <b-row>
                <b-col md="4" class="p-0">
                  <h5>Test Fee Paid</h5>
                  <div
                    class="d-flex align-items-center pr-50"
                    style="width: 100%"
                  >
                    <b-button
                      :variant="
                        myObj.testPayment ? 'primary' : 'outline-primary'
                      "
                      class="btn-icon rounded-circle mr-50"
                      @click="myObj.testPayment = !myObj.testPayment"
                    >
                      <feather-icon icon="CheckIcon" size="16" />
                    </b-button>
                    <!-- <b-form-checkbox id="checkbox1" v-model="prog.testPayment">
                    </b-form-checkbox> -->
                    <b-progress
                      style="width: 100%"
                      class="progress-bar-dark"
                      height="20px"
                      :value="myObj.testPayment ? '100' : '0'"
                      variant="success"
                    ></b-progress>
                  </div>
                </b-col>
                <b-col md="4" class="p-0">
                  <h5>Test Passed</h5>
                  <div
                    class="d-flex align-items-center pr-50"
                    style="width: 100%"
                  >
                    <b-button
                      :variant="
                        myObj.testPassed ? 'primary' : 'outline-primary'
                      "
                      class="btn-icon rounded-circle mr-50"
                      @click="myObj.testPassed = !myObj.testPassed"
                    >
                      <feather-icon icon="CheckIcon" size="16" />
                    </b-button>
                    <!-- <b-form-checkbox id="checkbox2" v-model="prog.test_passed">
                    </b-form-checkbox> -->
                    <b-progress
                      style="width: 100%"
                      class="progress-bar-dark"
                      height="20px"
                      :value="myObj.testPassed ? '100' : '0'"
                      variant="success"
                    ></b-progress>
                  </div>
                </b-col>
                <b-col md="4" class="p-0">
                  <h5>Admission Confirmed</h5>
                  <div class="d-flex align-items-center" style="width: 100%">
                    <!-- <b-form-checkbox id="checkbox3" v-model="prog.confirmed">
                    </b-form-checkbox> -->
                    <b-button
                      :variant="
                        myObj.testCompleted ? 'primary' : 'outline-primary'
                      "
                      class="btn-icon rounded-circle mr-50"
                      @click="myObj.testCompleted = !myObj.testCompleted"
                    >
                      <feather-icon icon="CheckIcon" size="16" />
                    </b-button>
                    <b-progress
                      class="progress-bar-dark"
                      style="width: 100%"
                      height="20px"
                      :value="myObj.testCompleted ? '100' : '0'"
                      variant="success"
                    ></b-progress>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>
      <!-- personal details -->
      <tab-content
        title="Family"
        icon="feather icon-users"
        :before-change="validationFamilyInfo"
      >
        <b-row v-if="$route.params.id > 0" class="mb-1">
          <b-col>
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="myObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ myObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  GR - {{ myObj.grNo }}
                </b-badge>
                <b-badge variant="light-primary" class="ml-50">
                  F# - {{ myObj.family_code }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-card class="">
              <h3 class="mt-1">Father Information</h3>
              <hr />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Occupation"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.father_occupation"
                      placeholder="Enter Occupation"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="CNIC no "
                    invalid-feedback="CNIC is required."
                  >
                    <b-form-input
                      ref="fCnic"
                      v-model="myObj.father_cnic"
                      placeholder="Enter CNIC number"
                    />
                    <!-- @focusout="CheckCnic()" -->
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Education"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.father_education"
                      placeholder="Enter Education"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Monthly income">
                    <b-form-input
                      type="number"
                      v-model="myObj.monthly_income"
                      placeholder="Enter monthly income"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    invalid-feedback="Email is required."
                  >
                    <b-form-input
                      ref="fEmail"
                      v-model="myObj.father_email"
                      placeholder="Enter Email"
                    />
                  </b-form-group>
                  <!-- @focusout="CheckEmail()" -->
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Employer (Firm)"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      v-model="myObj.father_employer"
                      placeholder="Enter Employer"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Office Contact"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      v-model="myObj.office_phone"
                      placeholder="Enter office number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Office Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter office address"
                      v-model="myObj.office_address"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- <b-col md="6">
                  <b-form-group
                    label="Fax"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input placeholder="Enter Fax" />
                  </b-form-group>
                </b-col> -->
              </b-row>
            </b-card>
          </b-col>

          <b-col md="6">
            <b-card class="">
              <h3 class="mt-1">Mother Information</h3>
              <hr />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Name"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_name"
                      placeholder="Enter name"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Occupation"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_occupation"
                      placeholder="Enter occupation"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="CNIC no"
                    invalid-feedback="CNIC is required."
                  >
                    <b-form-input
                      ref="mCnic"
                      v-model="myObj.mother_cnic"
                      placeholder="Enter CNIC number"
                    />
                    <!-- @focusout="CheckMCnic()" -->
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Education"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_education"
                      placeholder="Enter education."
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_email"
                      placeholder="Enter email address"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Employer"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_employer"
                      placeholder="Enter employer"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Office Contact"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_office_phone"
                      placeholder="Enter office contact"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Office Address"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.mother_office_address"
                      placeholder="Enter office address"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-card class="">
              <h3 class="mt-1">Emergency Information</h3>
              <hr />
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Name"
                    invalid-feedback="Name is required."
                  >
                    <b-form-input
                      ref="emerName"
                      placeholder="Enter name."
                      v-model="myObj.emergency_name"
                    />
                    <!-- @focusout="CheckEmerName()" -->
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Relation"
                    invalid-feedback="Relation is required."
                  >
                    <b-form-input
                      ref="emerRel"
                      placeholder="Enter relation."
                      v-model="myObj.emergency_relationship"
                    />
                    <!-- @focusout="CheckEmerRelation()" -->
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Emergency contact"
                    invalid-feedback="Emergency Contact is required."
                  >
                    <vue-tel-input
                      v-model="myObj.emergency_contact"
                      :inputOptions="emerOpt"
                      :dropdownOptions="dropOptions"
                      @input="CheckEmerContact"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="emerPhoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card class="">
              <h3 class="mt-1">Tutor Information</h3>
              <hr />
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Contact"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      v-model="myObj.contact_tutor"
                      placeholder="Enter tutor contact no"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>
      <!-- address -->
      <tab-content title="Info" icon="feather icon-users">
        <b-row v-if="$route.params.id > 0" class="mb-1">
          <b-col>
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="44"
                  :src="myObj.picture"
                  variant="light-primary"
                />
              </template>

              <b-link
                class="font-weight-bold d-block text-nowrap"
                style="margin-bottom: 4px"
              >
                <b-badge variant="light-primary">
                  {{ myObj.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  GR - {{ myObj.grNo }}
                </b-badge>
                <b-badge variant="light-primary" class="ml-50">
                  F# - {{ myObj.family_code }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col md="6">
            <b-card class="">
              <h3 class="mt-1">Guardian Information</h3>
              <hr />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Name"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter guardian name."
                      v-model="myObj.guardian_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Relation"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter guardian relation."
                      v-model="myObj.guardian_relationship"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Occupation"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter guardian occupation."
                      v-model="myObj.guardian_occupation"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="CNIC no"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      ref="gCnic"
                      placeholder="Enter CNIC number."
                      v-model="myObj.guardian_cnic"
                    />
                    <!-- @focusout="CheckGCnic()" -->
                  </b-form-group>
                </b-col>
                <!-- <b-col md="6">
                  <b-form-group
                    label="Mobile No"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input placeholder="Enter mobile number" />
                  </b-form-group>
                </b-col> -->
                <b-col md="6"> </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      ref="gEmail"
                      placeholder="Enter email addresss."
                      v-model="myObj.guardian_email"
                    />
                    <!-- @focusout="CheckGEMail()" -->
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Employer"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter employer."
                      v-model="myObj.guardian_employer"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Office Contact"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter office number"
                      v-model="myObj.guardian_office_phone"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Education"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter Education"
                      v-model="myObj.guardian_education"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Office Address"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      placeholder="Enter office contact number"
                      v-model="myObj.guardian_office_address"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card class="">
              <h3 class="mt-1">Other Information</h3>
              <hr />
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Allergies"
                    invalid-feedback="School name is required."
                  >
                    <b-form-input
                      placeholder="Enter allergies."
                      v-model="myObj.allergies"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Note"
                    invalid-feedback="Note is required."
                  >
                    <b-form-textarea
                      id="textarea-default"
                      placeholder="Enter note"
                      rows="3"
                      v-model="myObj.remarks"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Leaving Reason"
                    invalid-feedback="Leaving Reason is required."
                  >
                    <b-form-textarea
                      id="textarea-default"
                      placeholder="Enter leaving reason"
                      rows="3"
                      v-model="myObj.last_note"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>
      <!-- social link -->
      <tab-content title="Admission" icon="feather icon-alert-circle">
        <!-- :before-change="validationEmergency" icon-alert-triangle -->
        <b-row align-h="center">
          <b-col md="7">
            <b-row v-if="$route.params.id > 0" class="mb-1">
              <b-col>
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="44"
                      :src="myObj.picture"
                      variant="light-primary"
                    />
                  </template>

                  <b-link
                    class="font-weight-bold d-block text-nowrap"
                    style="margin-bottom: 4px"
                  >
                    <b-badge variant="light-primary">
                      {{ myObj.name }}
                    </b-badge>
                  </b-link>
                  <small class="text-muted">
                    <b-badge variant="light-primary">
                      GR - {{ myObj.grNo }}
                    </b-badge>
                    <b-badge variant="light-primary" class="ml-50">
                      F# - {{ myObj.family_code }}
                    </b-badge>
                  </small>
                </b-media>
              </b-col>
            </b-row>
            <b-card>
              <h3 class="mt-1">Admission Test</h3>
              <hr />
              <b-form-group
                label="Admission Test Date"
                invalid-feedback="Test date is required."
                ref="testDt"
                class="mb-2"
              >
                <flat-pickr
                  :config="config"
                  v-model="admObj.testDate"
                  class="form-control"
                  placeholder="Select date"
                />
              </b-form-group>

              <b-button variant="primary" class="mb-50 mr-50">
                Send Admit Card
              </b-button>
              <b-button
                variant="primary"
                class="mb-50 mr-50"
                @click="printAdmit()"
              >
                Print Admit Card
              </b-button>
              <b-button variant="primary" class="mb-50 mr-50" v-if="false">
                Print Test Paper
              </b-button>

              <b-form-group
                label="Admission Test Status"
                invalid-feedback="Test Status is required."
                ref="testSt"
                class="mt-1"
              >
                <v-select
                  v-model="admObj.testStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  :options="statusOptions"
                  :reduce="(opt) => opt.value"
                  label="text"
                  placeholder="Select status"
                />
              </b-form-group>
              <b-form-group
                label="Admission Test Remarks"
                invalid-feedback="Admission Remarks is required."
              >
                <b-form-textarea
                  placeholder="Enter remarks"
                  rows="3"
                  v-model="myObj.remarks"
                />
              </b-form-group>
            </b-card>
            <b-card>
              <h3 class="mt-1">Admission Interview</h3>
              <hr />
              <b-form-group
                label="Admission Interview Date"
                invalid-feedback="Interview date is required."
                ref="intDt"
              >
                <flat-pickr
                  :config="config"
                  v-model="admObj.interviewDate"
                  class="form-control"
                  placeholder="Select date"
                />
              </b-form-group>
              <b-form-group
                label="Admission Interview Remarks"
                invalid-feedback="Interview Remarks is required."
              >
                <b-form-textarea
                  placeholder="Enter remarks"
                  rows="3"
                  v-model="admObj.remarks"
                />
              </b-form-group>
              <b-form-group
                label="Admission Interview Status"
                invalid-feedback="Interview Status is required."
                ref="intSt"
              >
                <v-select
                  v-model="admObj.interviewStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  :options="statusOptions2"
                  :reduce="(opt) => opt.value"
                  label="text"
                  placeholder="Select status"
                />
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Documents" icon="feather icon-link">
        <b-row align-h="center">
          <b-col md="7">
            <b-row v-if="$route.params.id > 0" class="mb-1">
              <b-col>
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="44"
                      :src="myObj.picture"
                      variant="light-primary"
                    />
                  </template>

                  <b-link
                    class="font-weight-bold d-block text-nowrap"
                    style="margin-bottom: 4px"
                  >
                    <b-badge variant="light-primary">
                      {{ myObj.name }}
                    </b-badge>
                  </b-link>
                  <small class="text-muted">
                    <b-badge variant="light-primary">
                      GR - {{ myObj.grNo }}
                    </b-badge>
                    <b-badge variant="light-primary" class="ml-50">
                      F# - {{ myObj.family_code }}
                    </b-badge>
                  </small>
                </b-media>
              </b-col>
            </b-row>
            <!-- <div class="file-uploader">
              <h5>
                <b-spinner
                  variant="dark"
                  label="Spinning"
                  v-if="uploading"
                ></b-spinner>
                <span v-else> Upload Documents </span>
              </h5>
              <input
                ref="studentdocs"
                type="file"
                class="file_upload"
                @change="uploadDocument()"
                multiple="true"
              />
            </div> -->
            <b-row
              v-for="(dd, ind) in docData"
              :key="ind"
              class="mb-1 align-items-center"
            >
              <b-col md="11">
                <b-card
                  body-class="p-75"
                  class="rounded cursor-pointer mb-0"
                  @click="
                    dd.url ? openFile(dd.url) : $refs['doc' + ind][0].click()
                  "
                >
                  <div class="d-flex align-items-start justify-content-between">
                    <div>
                      <feather-icon
                        class="mr-50"
                        :icon="dd.url ? 'CheckCircleIcon' : 'AlertCircleIcon'"
                        :class="dd.url ? 'text-success' : 'text-warning'"
                        size="20"
                      />
                      <span class="font-weight-bold">{{ dd.meta_title }}</span>
                    </div>

                    <b-spinner
                      v-if="docLoading && currentIndex == ind"
                      small
                      variant="primary"
                    />
                    <feather-icon
                      v-else
                      :icon="dd.url ? 'DownloadIcon' : 'UploadIcon'"
                      size="20"
                    />
                  </div>
                </b-card>
                <input
                  type="file"
                  hidden
                  :ref="'doc' + ind"
                  @change="selectDoc(dd, ind)"
                  accept="image/*,.pdf"
                />
              </b-col>
              <b-col md="1" class="pl-md-0 mt-50 mt-md-0">
                <feather-icon
                  v-if="dd.url"
                  icon="TrashIcon"
                  class="text-danger cursor-pointer"
                  size="20"
                  @click="dd.url = ''"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row align-h="center">
          <b-col md="7">
            <b-row class="mb-1">
              <b-col
                md="11"
                class="d-flex flex-wrap align-items-center justify-content-between"
              >
                <h4 class="mb-0">Additional Docs</h4>
                <b-button
                  @click="$refs.studentdocs.click()"
                  variant="primary"
                  class="btn-icon"
                  :disabled="uploading"
                >
                  <input
                    ref="studentdocs"
                    type="file"
                    class="file_upload"
                    @change="uploadDocument()"
                    multiple="true"
                    accept="image/*,.pdf"
                    hidden
                  />
                  Upload More
                </b-button>
              </b-col>
            </b-row>

            <b-row
              v-for="(file, index) in documents"
              :key="index"
              class="mb-1 align-items-center"
            >
              <b-col md="11">
                <b-card
                  body-class="p-75"
                  class="rounded cursor-pointer mb-0"
                  @click="openFile(file)"
                >
                  <div class="d-flex align-items-start justify-content-between">
                    <div>
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="text-success mr-50"
                        size="20"
                      />
                      <span class="font-weight-bold">
                        {{ file.slice(0, 30) }} ...</span
                      >
                    </div>
                    <feather-icon icon="DownloadIcon" size="20" />
                  </div>
                </b-card>
              </b-col>
              <b-col md="1" class="pl-md-0 mt-50 mt-md-0">
                <feather-icon
                  icon="TrashIcon"
                  class="text-danger cursor-pointer"
                  size="20"
                  @click="deleteDocument(index)"
                />
              </b-col>
            </b-row>

            <!-- <b-row v-for="(file, index) in documents" :key="index" class="mb-1">
              <b-col md="11">
                <div class="d-flex align-items-start justify-content-between">
                  <div>
                    <feather-icon
                      icon="CheckCircleIcon"
                      class="text-success mr-50"
                      size="20"
                    />
                    <b-link :href="file" target="_blank" class="text-dark">
                      {{ file.slice(0, 30) }} ...
                    </b-link>
                  </div>
                  <b-link :href="file" target="_blank" class="text-dark">
                    <feather-icon icon="DownloadIcon" size="20" />
                  </b-link>
                </div>
              </b-col>
              <b-col md="1" class="pl-md-0 mt-50 mt-md-0">
                <feather-icon
                  icon="TrashIcon"
                  class="text-danger cursor-pointer"
                  size="20"
                  @click="deleteDocument(index)"
                />
              </b-col>
            </b-row> -->

            <!-- <template v-for="(file, index) in documents">
              <div class="file">
                <div style="display: flex; align-items: center">
                  <div class="file-type">
                    {{ file.split(".").pop().toUpperCase() }}
                  </div>
                  <b-link :href="file" target="_blank">
                    {{ file }}
                  </b-link>
                </div>
                <div class="file-delete">
                  <i
                    class="fa fa-trash"
                    aria-hidden="true"
                    @click="deleteDocument(index)"
                  ></i>
                </div>
              </div>
            </template> -->
          </b-col>
        </b-row>
      </tab-content>

      <tab-content
        :title="
          $store.state.userData.studentStatus.toLowerCase() == 'present'
            ? 'Contact Number'
            : 'WhatsApp'
        "
        icon="feather icon-smartphone"
      >
        <b-row class="match-height" align-h="center">
          <b-col md="6">
            <b-row v-if="$route.params.id > 0" class="mb-1">
              <b-col>
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="44"
                      :src="myObj.picture"
                      variant="light-primary"
                    />
                  </template>

                  <b-link
                    class="font-weight-bold d-block text-nowrap"
                    style="margin-bottom: 4px"
                  >
                    <b-badge variant="light-primary">
                      {{ myObj.name }}
                    </b-badge>
                  </b-link>
                  <small class="text-muted">
                    <b-badge variant="light-primary">
                      GR - {{ myObj.grNo }}
                    </b-badge>
                    <b-badge variant="light-primary" class="ml-50">
                      F# - {{ myObj.family_code }}
                    </b-badge>
                  </small>
                </b-media>
              </b-col>
            </b-row>
            <b-card class="">
              <h3 class="mt-1">Application Credentials</h3>
              <hr />
              <b-row>
                <b-col md="8">
                  <!-- <b-form-group
                    label="Father's Contact"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      ref="contacts"
                      placeholder="Enter contact number."
                      v-model="myObj.contact_father"
                      @focusout="CheckContact()"
                    />
                  </b-form-group> -->
                  <b-form-group
                    label="Father's Contact"
                    invalid-feedback="Please enter correct contact number."
                  >
                    <vue-tel-input
                      v-model="myObj.contact_father"
                      :inputOptions="vueTelOptions"
                      :dropdownOptions="dropOptions"
                      @input="CheckFContact"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="fphoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="8">
                  <!-- <b-form-group
                    label="Mother's Contact"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      ref="contacts"
                      placeholder="Enter contact number."
                      v-model="myObj.contact_mother"
                      @focusout="CheckContact()"
                    />
                  </b-form-group> -->
                  <b-form-group
                    label="Mother's Contact"
                    invalid-feedback="Please enter correct phone number."
                  >
                    <vue-tel-input
                      :inputOptions="vueTelOptions"
                      @input="CheckMContact"
                      :dropdownOptions="dropOptions"
                      v-model="myObj.contact_mother"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="mphoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="8">
                  <!-- <b-form-group
                    label="Student's Contact"
                    invalid-feedback="Contact number is required."
                  >
                    <b-form-input
                      ref="contacts"
                      placeholder="Enter contact number."
                      v-model="myObj.contact_student"
                      @focusout="CheckContact()"
                    />
                  </b-form-group> -->
                  <b-form-group
                    label="Student's Contact"
                    invalid-feedback="Please enter correct phone number."
                  >
                    <vue-tel-input
                      :inputOptions="vueTelOptions"
                      :dropdownOptions="dropOptions"
                      @input="CheckContact"
                      v-model="myObj.contact_student"
                    ></vue-tel-input>
                    <small class="mr-1 text-danger" v-if="phoneerror"
                      >Phone number is required.</small
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content
        title="
          Custom Fees
        "
        icon="feather icon-credit-card"
      >
        <!-- v-if="false" -->
        <b-row class="match-height" align-h="center">
          <b-col xl="6" lg="7" md="8">
            <b-row v-if="$route.params.id > 0" class="mb-1">
              <b-col>
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="44"
                      :src="myObj.picture"
                      variant="light-primary"
                    />
                  </template>

                  <b-link
                    class="font-weight-bold d-block text-nowrap"
                    style="margin-bottom: 4px"
                  >
                    <b-badge variant="light-primary">
                      {{ myObj.name }}
                    </b-badge>
                  </b-link>
                  <small class="text-muted">
                    <b-badge variant="light-primary">
                      GR - {{ myObj.grNo }}
                    </b-badge>
                    <b-badge variant="light-primary" class="ml-50">
                      F# - {{ myObj.family_code }}
                    </b-badge>
                  </small>
                </b-media>
              </b-col>
            </b-row>
            <b-card>
              <h3 class="mt-1">Custom fee</h3>
              <hr />
              <div
                v-for="(item, ind) in customObj"
                :key="ind"
                style="display: flex; align-items: center"
              >
                <b-col xl="7" lg="7" md="6" class="p-0">
                  <b-form-group
                    label="Fee Type"
                    invalid-feedback="Fee Type is required."
                    ref="feetype"
                  >
                    <v-select
                      :id="'feetype' + ind"
                      v-model="item.feeTypeID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      :options="FeeTypes"
                      :reduce="(opt) => opt.id"
                      :selectable="(opt) => !feeIds.includes(opt.id)"
                      label="feeType"
                      :disabled="dataloading"
                      placeholder="Select fee type"
                      @input="changeOptions(ind)"
                    />
                  </b-form-group>
                </b-col>
                <b-col xl="3" lg="3" md="4" class="pr-0">
                  <b-form-group
                    label="Amount"
                    invalid-feedback="Amount is required."
                    ref="amount"
                  >
                    <b-form-input
                      type="number"
                      placeholder=""
                      ref="amount"
                      v-model="item.amount"
                    />
                  </b-form-group>
                </b-col>

                <b-col xl="2" lg="2" md="2" class="mt-1 pr-0">
                  <b-button
                    v-if="ind == customObj.length - 1"
                    @click="AddValue()"
                    variant="primary"
                    class="btn-icon rounded-circle mr-50"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="customObj.length > 1"
                    @click="removeValue(item, ind)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-col>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content
        title="
         Subjects
        "
        icon="feather icon-book"
      >
        <b-row class="match-height" align-h="center">
          <b-col md="8">
            <b-row v-if="$route.params.id > 0" class="mb-1">
              <b-col>
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="44"
                      :src="myObj.picture"
                      variant="light-primary"
                    />
                  </template>

                  <b-link
                    class="font-weight-bold d-block text-nowrap"
                    style="margin-bottom: 4px"
                  >
                    <b-badge variant="light-primary">
                      {{ myObj.name }}
                    </b-badge>
                  </b-link>
                  <small class="text-muted">
                    <b-badge variant="light-primary">
                      GR - {{ myObj.grNo }}
                    </b-badge>
                    <b-badge variant="light-primary" class="ml-50">
                      F# - {{ myObj.family_code }}
                    </b-badge>
                  </small>
                </b-media>
              </b-col>
            </b-row>
            <b-card>
              <h3 class="mt-1">Subjects Enrollment</h3>
              <hr />
              <b-table
                class="mt-1"
                :items="subjectData"
                :fields="subFields"
                responsive
                show-empty
              >
                <template #cell(subjects)="data">
                  <b-badge variant="light-primary">
                    {{ data.item.subName }}
                  </b-badge>
                </template>
                <template #cell(status)="data">
                  <b-badge
                    :variant="
                      data.item.isExcluded ? 'light-danger' : 'light-success'
                    "
                  >
                    {{ data.item.isExcluded ? "Excluded" : "Enrolled" }}
                  </b-badge>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    v-if="data.item.isExcluded"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-success"
                    v-b-tooltip.hover.left
                    title="Enroll"
                    class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                    @click="data.item.isExcluded = false"
                  >
                    <feather-icon icon="CheckIcon" class="" />
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-danger"
                    v-b-tooltip.hover.left
                    title="Exclude"
                    class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                    @click="data.item.isExcluded = true"
                  >
                    <feather-icon icon="XIcon" class="" />
                  </b-button>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </tab-content>

      <!-- next & prev buttons -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left d-none"></div>
        <div class="wizard-footer-right d-none"></div>
      </template>
    </form-wizard>

    <b-row align-h="center">
      <b-col md="6">
        <b-button block variant="success" @click="shortcutSubmit()">
          {{
            myObj.status.toLowerCase() == "registered" &&
            (this.$route.params.readmit == true ||
              this.$route.params.readmit == "true")
              ? "Confirm Admission"
              : "Save"
          }}
        </b-button>
        <b-button
          v-if="fObj && fObj.valueBool"
          class="mt-1"
          block
          variant="success"
          @click="shortcutSubmit('common')"
        >
          Save & Update family common data
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { codeIcon } from "./code";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
// import DropZone from 'dropzone-vue';
import vue2Dropzone from "vue2-dropzone";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
// // optionally import default styles
// import 'dropzone-vue/dist/dropzone-vue.common.css';

// createApp(App)
//   .use(DropZone)
//   .mount('#app');
// import 'dropzone-vue/dist/dropzone-vue.common.css';

// optionally import default styles
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BMediaAside,
  BImg,
  BMedia,
  BLink,
  BSpinner,
  BBadge,
  BFormFile,
  BFormTextarea,
  BProgress,
  BProgressBar,
  BFormCheckbox,
  BSidebar,
  BForm,
  BInputGroupPrepend,
  BInputGroup,
  BTable,
  VBTooltip,
  BAvatar,
} from "bootstrap-vue";
import { resolve } from "path";
import { reject } from "q";
import { report } from "process";
export default {
  props: ["selected", "id", "inqObj"],
  components: {
    vueDropzone: vue2Dropzone,
    // DropZone,
    codeIcon,
    BSidebar,
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    BTable,
    BFormCheckbox,
    BProgress,
    BProgressBar,
    BFormTextarea,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BCard,
    BButton,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BSpinner,
    BBadge,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VBTooltip,
    BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      commonData: false,
      settings: {
        sameDate: false,
        sameClass: false,
        sameSec: false,
      },
      visibility1: false,
      file: [],
      mydocs: [],
      moreloading: false,
      plus: "",
      logoloading: false,
      netAmount: 0,
      dataloading: false,
      admittedSections: [],
      currentSections: [],
      classes: [],
      genders: ["Male", "Female"],

      categories: [],
      prog: {
        test_fee: false,
        test_passed: false,
        confirmed: false,
      },
      groups: ["A", "B", "O", "AB"],
      religions: ["ISLAM", "CHRISTIANITY", "HINDUISM", "BUDDHISM", "SIKHISM"],
      areaOptions: [],
      codeIcon,
      documents: [],
      uploading: false,
      customObj: [
        {
          id: 0,
          sID: this.$route.params.id,
          feeTypeID: 0,
          amount: 0,
          campusID: this.$store.state.userData.cId,
        },
      ],
      allSubjects: [],
      subjectData: [],
      subFields: [
        { label: "subjects", key: "subjects" },
        { label: "status", key: "status" },
        { key: "actions", label: "actions" },
      ],
      sessionbar: false,
      savingacc: false,
      sessFields: [
        { label: "Name", key: "session" },
        { key: "actions", label: "actions" },
      ],
      allSess: [],
      sessionObj: {
        id: 0,
        session: "",
        campusID: this.$store.state.userData.cId,
      },
      accIcon: "",
      visibility2: false,
      disData: [],
      disFields: [
        { label: "Name", key: "name" },
        { key: "actions", label: "actions" },
      ],
      disObj: {},
      disOptions: [
        { text: "Amount", value: "amount" },
        { text: "Percentage", value: "percentage" },
      ],
      disTypes: [
        { text: "Fixed", value: "fixed" },
        { text: "Variable", value: "variable" },
      ],
      disableDiscount: false,
      savingDis: false,
      myObj: {
        id: 0,
        grNo: null,
        grNoLocal: null,
        receiptNo: 0,
        family_code: null,
        name: null,
        father_name: null,
        gender: null,
        picture: null,
        date_of_birth: null,
        date_of_birth_words: null,
        date_of_admission: null,
        blood_group: null,
        nationality: null,
        religion: null,
        place_of_birth: null,
        mother_tongue: null,
        address_current: null,
        address_permanent: null,
        contact_student: null,
        contact_father: null,
        contact_mother: null,
        contact_home: null,
        contact_tutor: null,
        last_institute: null,
        last_class: null,
        last_session: null,
        last_result: null,
        last_note: null,
        class_admitted: 0,
        cls_admitted: null,
        class_current: 0,
        cls_current: null,
        section_admitted: "",
        section_current: "",
        roll_number: null,
        house: null,
        rfid_number: null,
        bform_number: null,
        date_of_registraion: null,
        date_of_readmission: null,
        father_cnic: null,
        father_education: null,
        father_occupation: null,
        monthly_income: null,
        office_phone: null,
        office_address: null,
        father_email: null,
        father_employer: null,
        mother_name: null,
        mother_cnic: null,
        mother_education: null,
        mother_occupation: null,
        mother_office_phone: null,
        mother_office_address: null,
        mother_email: null,
        mother_employer: null,
        guardian_name: null,
        guardian_cnic: null,
        guardian_education: null,
        guardian_occupation: null,
        guardian_office_phone: null,
        guardian_office_address: null,
        guardian_email: null,
        guardian_employer: null,
        guardian_relationship: null,
        emergency_name: null,
        emergency_contact: null,
        emergency_relationship: null,
        physical_handicap: null,
        allergies: null,
        remarks: null,
        monthly_fee: 0,
        discount_percentage: 0,
        challan_due_date: null,
        docs: "",
        status: this.$store.state.userData.studentStatus,
        campusID: this.$store.state.userData.cId,
        testPayment: false,
        testPassed: false,
        testCompleted: false,
        sessionID: 0,
        currency: "Rs",
        classFee: 0,
        discountReferenceID: 0,
      },
      fphoneerror: false,
      mphoneerror: false,
      phoneerror: false,
      emerPhoneerror: false,
      vueTelOptions: { placeholder: "Enter contact number" },
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      emerOpt: { placeholder: "Enter emergency contact" },
      classesOptions: [],
      check: 1,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      fields: [
        { label: "Family Code", key: "family_code" },
        { label: "father name", key: "father_name" },
        { label: "contact", key: "contact_father" },
      ],
      studentData: [],
      searchQuery: "",
      visibility: false,
      studentLoading: false,
      initData: null,
      feeAmount: null,
      FeeTypes: [],
      feeIds: [],
      addingMsg: this.$store.state.addMsg,
      uRights: {
        hideFee: false,
      },
      admObj: {
        id: 0,
        sID: this.$route.params.id,
        campusID: this.$store.state.userData.cId,
        testDate: null,
        testStatus: "",
        interviewDate: null,
        interviewStatus: "",
        remarks: "",
        documents: "",
      },
      statusOptions: [
        { text: "Cleared", value: "cleared" },
        { text: "Undertaking", value: "undertaking" },
        { text: "Not Cleared", value: "not cleared" },
      ],
      statusOptions2: [
        { text: "Cleared", value: "cleared" },
        { text: "Not Cleared", value: "not cleared" },
      ],
      docData: [],
      fObj: null,
      otherObj: null,
      disSetting: null,
      dCopy: {},
      visibility3: false,
      savingSett: false,
      schGr: null,
      spNo: null,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      campuses: [],
      currentIndex: 0,
      docLoading: false,
    };
  },
  created() {
    // this.$store.state.menu.forEach((el) => {
    //   el.children.forEach((ch) => {
    //     console.log("ch", ch.route, this.$route.name);
    //     if (ch.route == this.$route.name) {
    //       if (this.$route.params.id == 0 && ch.add) console.log("allowed");
    //       else if (this.$route.params.id != 0 && ch.edit)
    //         console.log("allowed edit");
    //       this.$router.go(-1);
    //     }
    //   });
    // });

    // console.log(
    //   "----------",
    //   this.selected,
    //   this.$route.params.readmit,
    //   this.$route.params.id
    // );
    // console.log(this.inqObj);
    if (this.inqObj) {
      this.$route.params.id = parseInt(this.$route.params.id);
      let data = this.inqObj.inquiry;

      this.myObj.name = data.name;
      this.myObj.father_name = data.father_name;
      this.myObj.class_admitted = data.cls;
      this.myObj.class_current = data.cls;
      this.myObj.contact_father = data.contact;
      this.myObj.father_cnic = data.cnic;
      this.myObj.father_email = data.email;
      this.myObj.last_institute = data.lastSchool;
      this.myObj.address_current = data.residentialArea;
      this.myObj.address_permanent = data.residentialArea;
      this.myObj.campusID = data.campusID;

      this.myObj.docs = data.attachments;
      if (data.attachments !== null && data.attachments !== "") {
        this.documents = data.attachments.split(",");
      } else this.documents = [];

      this.$store.commit("stdStatus", "registered");
      this.myObj.status = this.$store.state.userData.studentStatus;
      this.LoadData(this.myObj.campusID);
      this.loadSubjects();
      this.loadFeeType();
      this.LoadSession();
      this.settings = { ...this.$store.state.studentSetting };
      this.LoadSettings();
      this.LoadSettingsAll();
      this.LoadAreas();
    } else {
      var right =
        this.$store.state.rights["manage-studentgrid"] ||
        this.$store.state.rights["manage-enrolled"] ||
        this.$store.state.rights["student-enrolled"] ||
        this.$store.state.rights["student-registered"];
      this.uRights = right;
      if (!right) {
        this.$router.replace({
          name: "misc-not-authorized",
        });
      } else {
        this.$route.params.id = parseInt(this.$route.params.id);

        if (this.uRights.hideFee == undefined) this.uRights.hideFee = false;
        if (this.$route.params.id > 0 && right.edit) {
          this.LoadStudent();
          this.loadFeeType();
          this.LoadSession();
          this.LoadSettings();
          this.LoadSettingsAll();
          this.LoadAreas();
          //   if (
          //   this.reportDomain == "myskoolhpgs" &&
          // ) {
          // }
          // this.LoadCampuses();
        } else if (this.$route.params.id == 0 && right.add) {
          if (this.selected === "add") {
            this.$store.commit("stdStatus", "present");
            // console.log(this.$store.state.userData.studentStatus);
            this.myObj.status = this.$store.state.userData.studentStatus;
            this.LoadData();
            this.LoadInit();
          } else if (this.selected === "reg") {
            this.$store.commit("stdStatus", "registered");
            // console.log(this.$store.state.userData.studentStatus);
            this.myObj.status = this.$store.state.userData.studentStatus;
            this.LoadData();
            this.LoadInit();
          } else if (this.selected == undefined) {
            // console.log(this.$store.state.userData.studentStatus);
            this.myObj.status = this.$store.state.userData.studentStatus;
            // console.log(this.myObj);
            this.LoadData();
          }
          this.loadSubjects();
          this.loadFeeType();
          this.LoadSession();
          this.LoadSettings();
          this.LoadSettingsAll();
          this.LoadAreas();
          this.settings = { ...this.$store.state.studentSetting };
          //   if (
          //   this.reportDomain == "myskoolhpgs" &&
          // ) {
          // }
          // this.LoadCampuses();
          // console.log(this.settings);
        } else {
          this.$router.replace({
            name: "misc-not-authorized",
          });
        }
      }
    }
  },
  mounted() {
    this.$refs.wizard.activateAll();
    // window.addEventListener("keydown", async (event) => {
    // });

    window.addEventListener("keydown", this.handleEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent);
  },
  computed: {
    ...mapGetters({ campusID: "campusID" }),
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openFile(url) {
      window.open(url, "_blank");
    },

    numberToWords(number) {
      const words = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      const tens = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];

      if (number < 20) return words[number];
      if (number < 100)
        return (
          tens[Math.floor(number / 10)] +
          (number % 10 === 0 ? "" : " " + words[number % 10])
        );
      if (number < 1000)
        return (
          this.numberToWords(Math.floor(number / 100)) +
          " Hundred" +
          (number % 100 === 0 ? "" : " and " + this.numberToWords(number % 100))
        );

      // For this specific use case, we assume the year is always in the 2000s
      return (
        "Two Thousand" +
        (number % 1000 !== 0 ? " " + this.numberToWords(number % 1000) : "")
      );
    },
    formatDate() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const dayNames = [
        "",
        "First",
        "Second",
        "Third",
        "Fourth",
        "Fifth",
        "Sixth",
        "Seventh",
        "Eighth",
        "Ninth",
        "Tenth",
        "Eleventh",
        "Twelfth",
        "Thirteenth",
        "Fourteenth",
        "Fifteenth",
        "Sixteenth",
        "Seventeenth",
        "Eighteenth",
        "Nineteenth",
        "Twentieth",
        "Twenty First",
        "Twenty Second",
        "Twenty Third",
        "Twenty Fourth",
        "Twenty Fifth",
        "Twenty Sixth",
        "Twenty Seventh",
        "Twenty Eighth",
        "Twenty Ninth",
        "Thirtieth",
        "Thirty First",
      ];

      const [yearStr, monthStr, dayStr] = this.myObj.date_of_birth.split("-");
      const day = parseInt(dayStr, 10);
      const year = parseInt(yearStr, 10);
      const monthIndex = parseInt(monthStr, 10) - 1;

      this.myObj.date_of_birth_words = `${dayNames[day]} ${
        months[monthIndex]
      } ${this.numberToWords(year)}`;
    },

    printAdmit() {
      if (this.myObj.id > 0) {
        let dt = new Date().toJSON().split("T")[0];
        let url =
          `https://${this.reportDomain}.myskool.app/Certificate/Show?type=admit-card` +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&sID=" +
          this.myObj.id +
          "&exam=Admission Test&session=&date=" +
          dt;

        window.open(url, "_blank");
      }
    },
    setMsg() {
      this.$store.commit("setAddMsg", this.addingMsg);
    },

    handleEvent(event) {
      // console.log(event.key);
      if (event.ctrlKey && event.keyCode === 83) {
        event.preventDefault();
        if (this.check == 1) {
          this.check = 0;
          // console.log(this.check);
          this.shortcutSubmit();
        }
      }
    },
    setSameFields(field) {
      if (this.selected === "add" || this.selected === "reg") {
        if (field == "date" && this.settings.sameDate) {
          this.myObj.date_of_admission = this.myObj.date_of_registraion;
        } else if (field == "class" && this.settings.sameClass) {
          this.myObj.class_current = this.myObj.class_admitted;
          this.FillSectionsCurrent("select");
        } else if (field == "section" && this.settings.sameSec) {
          let sec = this.currentSections.find(
            (el) => el.id == this.myObj.section_admitted
          );
          if (sec) {
            this.myObj.section_current = this.myObj.section_admitted;
          }
        }
      }
    },
    async saveSettings() {
      // console.log(this.settings);
      this.$store.commit("setStdSetting", this.settings);
      this.visibility1 = false;
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: "Notification",
      //     icon: "CheckIcon",
      //     text: "Settings saved successfully!",
      //     variant: "success",
      //   },
      // });
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },
    async loadSubjects() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allSubjects = await this.get(obj2);
      // console.log(this.allSubjects);
      if (this.$route.params.id == 0) this.setSubs();
    },
    setSubs() {
      this.subjectData = this.allSubjects.map((el) => {
        return {
          id: 0,
          sID: this.$route.params.id,
          subID: el.id,
          subName: el.subject,
          isExcluded: false,
          campusID: this.$store.state.userData.cId,
        };
      });
      // console.log(this.subjectData);
    },
    async LoadSession() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allSess = await this.get(obj2);
      // console.log("Session", this.allSess);
      if (this.selected == "add" || this.selected == "reg") {
        let obj = this.allSess.find((el) => el.isCurrentSession == true);
        if (!obj) this.myObj.sessionID = 0;
        else this.myObj.sessionID = obj.id;
      }
    },
    openSession() {
      this.accIcon = "PlusIcon";
      this.sessionbar = true;

      this.sessionObj = {
        id: 0,
        session: "",
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["sess_name"];
      elem.state = undefined;
    },
    SessName() {
      var elem = this.$refs["sess_name"];
      if (this.sessionObj.session.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async setCurrentSess(id) {
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Sessions/SetCurrentSession?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&sessionID=" +
          id,
        body: this.sessionObj,
        message: "Session marked as current",
        context: this,
        token: this.$store.state.userData.token,
      });

      if (status) {
        this.LoadSession();
      }
    },
    async saveSession() {
      if (this.SessName() == true) {
        this.savingacc = true;
        if (this.sessionObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Sessions?db=" +
              this.$store.state.userData.db,
            body: this.sessionObj,
            message: "Session added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Sessions/" +
              this.sessionObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Session updated successfully.",
            context: this,
            body: this.sessionObj,
            token: this.$store.state.userData.token,
          });
        }
        this.savingacc = false;
        if (status) {
          this.LoadSession();
          var elem = this.$refs["sess_name"];
          elem.state = undefined;
          this.sessionObj = {
            id: 0,
            session: "",
            campusID: this.$store.state.userData.cId,
          };
        }
      }
    },
    async editSess(item) {
      this.sessionObj = { ...item };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["sess_name"];
      elem.state = undefined;
      // console.log(this.sessionObj);
    },
    async deleteSess(item) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Sessions/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Session removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadSession();
      }
    },

    openDSett() {
      if (this.disSetting) {
        this.dCopy = { ...this.disSetting };
      } else {
        this.dCopy = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "discount_ref",
          value: "discount_ref",
          valueBool: false,
          valueNumber: null,
        };
      }
      this.visibility3 = true;
    },
    async saveDSett() {
      this.savingSett = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Settings/SaveNew?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: [this.dCopy],
        message: "Settings saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.savingSett = false;
      if (status) {
        this.LoadSettingsAll();
        this.visibility3 = false;
      }
    },

    async LoadDis(text) {
      var obj2 = {
        url:
          this.$store.state.domain +
          "DiscountReference?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.disData = await this.get(obj2);
      if (text == "filled" && this.$route.params.id > 0) {
        if (this.myObj.discountReferenceID > 0) {
          let obj = this.disData.find(
            (el) => el.id == this.myObj.discountReferenceID
          );
          if (obj) {
            if (obj.discountType == "fixed") this.disableDiscount = true;
            else this.disableDiscount = false;
          }
        } else this.disableDiscount = false;
      }
    },
    openDis() {
      this.visibility2 = true;

      this.disObj = {
        id: 0,
        name: "",
        calculatedBy: "amount",
        discountType: "fixed",
        amount: 0,
        percentage: 0,
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["d_name"];
      elem.state = undefined;
      var elem = this.$refs["d_amount"];
      elem.state = undefined;
    },
    CheckDName() {
      var elem = this.$refs["d_name"];
      if (this.disObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDAmount() {
      if (this.disObj.calculatedBy == "amount") {
        var elem = this.$refs["d_amount"];
        let x = parseInt(this.disObj.amount);
        if (isNaN(x) || x <= 0) {
          return (elem.state = false);
        } else {
          this.disObj.amount = x;
          return (elem.state = true);
        }
      } else return true;
    },
    CheckDPerc() {
      if (this.disObj.calculatedBy == "percentage") {
        var elem = this.$refs["d_perc"];
        let x = parseFloat(this.disObj.percentage);
        if (isNaN(x) || x <= 0 || x > 100) {
          return (elem.state = false);
        } else {
          this.disObj.percentage = x;
          return (elem.state = true);
        }
      } else return true;
    },
    async saveDis() {
      this.CheckDName();
      this.CheckDAmount();
      this.CheckDPerc();
      if (
        this.CheckDName() == true &&
        this.CheckDAmount() == true &&
        this.CheckDPerc() == true
      ) {
        // console.log(this.disObj);
        this.savingDis = true;
        if (this.disObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "DiscountReference?db=" +
              this.$store.state.userData.db,
            body: this.disObj,
            message: "Discount Reference added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "DiscountReference/" +
              this.disObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Discount Reference updated successfully.",
            context: this,
            body: this.disObj,
            token: this.$store.state.userData.token,
          });
        }
        this.savingDis = false;
        if (status) {
          this.LoadDis();

          this.disObj = {
            id: 0,
            name: "",
            calculatedBy: "amount",
            discountType: "fixed",
            amount: 0,
            percentage: 0,
            campusID: this.$store.state.userData.cId,
          };
          var elem = this.$refs["d_name"];
          elem.state = undefined;
          var elem = this.$refs["d_amount"];
          elem.state = undefined;
        }
      }
    },
    async editDis(item) {
      this.disObj = { ...item };
      var elem = this.$refs["d_name"];
      elem.state = undefined;
    },
    async deleteDis(id) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "DiscountReference/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Discount Reference removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadDis();
      }
    },
    setDiscount() {
      if (this.myObj.discountReferenceID > 0) {
        let obj = this.disData.find(
          (el) => el.id == this.myObj.discountReferenceID
        );
        if (obj) {
          if (obj.discountType == "fixed") this.disableDiscount = true;
          else this.disableDiscount = false;
          if (obj.calculatedBy == "amount") this.myObj.discount = obj.amount;
          else {
            this.myObj.discount = parseInt(
              (obj.percentage / 100) * this.myObj.classFee
            );
          }
        }
      } else this.disableDiscount = false;
    },
    checkDis() {
      var elem = this.$refs["dis"];
      let x = parseInt(this.myObj.discount);
      // || x > this.myObj.classFee
      if (isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        this.myObj.discount = x;
        return (elem.state = true);
      }
    },
    setFee() {
      if (this.checkDis() == true) {
        let res = this.myObj.classFee - this.myObj.discount;
        this.myObj.monthly_fee = res < 0 ? 0 : res;
      }
    },

    async loadFeeType() {
      this.FeeTypes - [];
      var obj = {
        url:
          this.$store.state.domain +
          "FeeType/LoadAll?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.FeeTypes = await this.get(obj);
      this.FeeTypes = this.FeeTypes.filter((el) => el.recurrence != "default");
    },
    changeOptions(ind) {
      this.feeIds = this.customObj.reduce((acc, el) => {
        acc.push(el.feeTypeID);
        return acc;
      }, []);
      // console.log(this.feeIds);
    },
    AddValue() {
      this.customObj.push({
        id: 0,
        sID: this.$route.params.id,
        feeTypeID: 0,
        amount: 0,
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.customObj);
    },
    async removeValue(item, ind) {
      if (item.id == 0) {
        this.customObj.splice(ind, 1);
        this.feeIds = this.feeIds.filter((el) => el != item.feeTypeID);
        // console.log(this.feeIds,this.customObj);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StudentsCustomFee/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.customObj.splice(ind, 1);
          this.feeIds = this.feeIds.filter((el) => el != item.feeTypeID);
        }
        // console.log(this.customObj);
      }
    },
    CheckValues() {
      let state = true;
      this.customObj = this.customObj.filter((el) => el.feeTypeID != 0);
      this.customObj.forEach((el) => {
        el.sID = parseInt(el.sID);
        el.amount = parseInt(el.amount);
        if (el.feeTypeID !== 0 && (isNaN(el.amount) || el.amount < 0)) {
          state = false;
        }
      });
      return state;
    },

    showSidebar() {
      this.visibility = true;
    },
    hideSideBar() {
      this.visibility = false;
    },
    onRowSelected(row, val) {
      // console.log(row, val);
      let std = {};
      if (row) std = row[0];
      else std = val;
      this.myObj.family_code = std.family_code;

      this.myObj.contact_father = std.contact_father;
      this.myObj.father_cnic = std.father_cnic;
      this.myObj.father_education = std.father_education;
      this.myObj.father_email = std.father_email;
      this.myObj.father_employer = std.father_employer;
      this.myObj.father_name = std.father_name;
      this.myObj.father_occupation = std.father_occupation;
      this.myObj.office_address = std.office_address;
      this.myObj.office_phone = std.office_phone;
      this.myObj.monthly_income = std.monthly_income;

      this.myObj.guardian_cnic = std.guardian_cnic;
      this.myObj.guardian_education = std.guardian_education;
      this.myObj.guardian_email = std.guardian_email;
      this.myObj.guardian_employer = std.guardian_employer;
      this.myObj.guardian_name = std.guardian_name;
      this.myObj.guardian_occupation = std.guardian_occupation;
      this.myObj.guardian_office_address = std.guardian_office_address;
      this.myObj.guardian_office_phone = std.guardian_office_phone;
      this.myObj.guardian_relationship = std.guardian_relationship;

      this.myObj.contact_mother = std.contact_mother;
      this.myObj.mother_cnic = std.mother_cnic;
      this.myObj.mother_education = std.mother_education;
      this.myObj.mother_email = std.mother_email;
      this.myObj.mother_employer = std.mother_employer;
      this.myObj.mother_name = std.mother_name;
      this.myObj.mother_occupation = std.mother_occupation;
      this.myObj.mother_office_address = std.mother_office_address;
      this.myObj.mother_office_phone = std.mother_office_phone;

      this.myObj.emergency_contact = std.emergency_contact;
      this.myObj.emergency_name = std.emergency_name;
      this.myObj.emergency_relationship = std.emergency_relationship;

      this.myObj.address_permanent = std.address_permanent;
      this.myObj.address_current = std.address_current;

      // console.log(this.myObj);
      this.visibility = false;
    },
    async searchByFamily() {
      if (
        (this.selected == "add" || this.$route.params.id > 0) &&
        this.myObj.family_code != null &&
        this.myObj.family_code != ""
      ) {
        // console.log(this.myObj.family_code);
        let myitem = await this.get({
          url:
            this.$store.state.domain +
            "Students/LoadFamilyData?fCode=" +
            this.myObj.family_code +
            "&campusId=" +
            this.$store.state.userData.cId +
            "&db=" +
            this.$store.state.userData.db,
          token: this.$store.state.userData.token,
        });
        // console.log("data", myitem);
        if (myitem) this.onRowSelected(null, myitem);
        // else console.log("not found");
      }
    },
    async loadSearch() {
      //  this.items =[];
      this.studentLoading = true;
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "Students/SearchFamilies?keyword=" +
          this.searchQuery +
          "&campusId=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      });
      this.studentData = myitem;
      // console.log("stud", this.studentData);
      this.studentLoading = false;
    },

    uploadDocument(e) {
      // console.log(e.target.files);
      // this.documents = Array.from(e.target.files);
      // console.log(this.documents);
      let docs = this.$refs.studentdocs.files;
      if (docs.length != 0) {
        this.uploading = true;
        let formData = new FormData();
        docs.forEach((el) => {
          formData.append("file", el);
        });
        var axios = require("axios");
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.documents.push(el.path);
            });
            this.myObj.docs = this.documents.join(",");
            this.uploading = false;
            // console.log(this.myObj.docs);
          })
          .finally(() => (this.uploading = false));
      }
    },
    deleteDocument(index) {
      // console.log(index);
      this.documents.splice(index, 1);
      this.myObj.docs = this.documents.join(",");
      // console.log(this.myObj.docs);
    },
    selectDoc(item, ind) {
      let obj = this.$refs["doc" + ind][0].files;
      // console.log(obj);

      if (obj.length > 0) {
        this.currentIndex = ind;
        this.docLoading = true;
        let formData = new FormData();
        formData.append("file", obj[0]);

        var axios = require("axios");
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let res = e.data.myresp[0].path;
            item.url = res;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => (this.docLoading = false));
      }
    },

    CheckRequired(name) {
      var elem = this.$refs[name];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }

      // return (elem.state = this.$v.depObj.name.required);
    },
    saveForm() {
      // console.log(e);
      this.$bvToast.toast("This GR number is already registerd!", {
        title: "Error!",
        variant: "danger",
        solid: true,
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        // resolve(true);
        this.CheckName();
        this.CheckFName();
        this.CheckDob();
        this.CheckClassAdmit();
        this.CheckClassCurrent();
        this.CheckCurrentSec();
        this.CheckAdmitSec();
        this.CheckGrNo();
        this.CheckFees();
        if (
          this.CheckName() == false ||
          this.CheckFName() == false ||
          this.CheckDob() == false ||
          this.CheckClassAdmit() == false ||
          this.CheckClassCurrent() == false ||
          this.CheckCurrentSec() == false ||
          this.CheckAdmitSec() == false ||
          this.CheckFees() == false ||
          this.CheckGrNo() == false
        ) {
          reject(false);
        } else {
          if (this.myObj.id == 0) {
            resolve(true); //
            var axios = require("axios");
            var config = {
              method: "get",
              url:
                this.$store.state.domain +
                "students/CheckGR/" +
                this.myObj.campusID +
                "/" +
                this.myObj.grNo +
                "?db=" +
                this.$store.state.userData.db,
              headers: {
                Authorization:
                  "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhYmMiLCJqdGkiOiI4NmQyMDQ3NC0xZjg5LTQ5NWItYjc2MC04NzY4NjI1MWJkYTEiLCJleHAiOjE2NzA0MjAyNzksImlzcyI6Im15c2tvb2wuYXBwIiwiYXVkIjoibXlza29vbC5hcHAifQ.jPCDB2X_5FAc9MrT-2oaFrcfM6-fwJ64isfQ_PCxzYM",
              },
            };
            console.log("----------------------", config);
            axios(config)
              .then((response) => {
                console.log(response.data);
                if (response.data.data == "notexist") {
                  resolve(true);
                } else if (response.data.data == "exist") {
                  this.$bvToast.toast("This GR number is already registerd!", {
                    title: "Error!",
                    variant: "danger",
                    solid: true,
                  });
                  reject();
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            resolve(true);
          }
        }
      });
    },
    validationFamilyInfo() {
      return new Promise((resolve, reject) => {
        resolve(true);
        // this.CheckCnic();
        // this.CheckMCnic();
        // this.CheckEmail();
        // if (
        //   this.CheckCnic() == false ||
        //   this.CheckMCnic() == false ||
        //   this.CheckEmail() == false
        // ) {
        //   reject(false);
        // } else {
        //   resolve(true);
        // }
      });
    },
    validationEmergency() {
      return new Promise((resolve, reject) => {
        resolve(true);
        // this.CheckEmerName();
        // this.CheckEmerRelation();
        // this.CheckEmerContact();
        // if (
        //   this.CheckEmerName() == false ||
        //   this.CheckEmerRelation() == false ||
        //   this.CheckEmerContact() == false
        // ) {
        //   reject(false);
        //   console.log("rej");
        // } else {
        //   resolve(true);
        // }
      });
    },
    async shortcutSubmit(val) {
      // console.log(val);
      // console.log("obj", this.myObj);
      this.check = 0; // due to save button click
      this.CheckName();
      this.CheckFName();
      // this.CheckDob();
      this.CheckClassAdmit();
      this.CheckClassCurrent();
      this.CheckCurrentSec();
      this.CheckAdmitSec();
      this.CheckGrNo();
      this.CheckFees();
      if (this.CheckName() == false) {
        this.$bvToast.toast("Please enter the name", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckFName() == false) {
        this.$bvToast.toast("Please enter the father name", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckGrNo() == false) {
        this.$bvToast.toast("Please enter the gr number", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckClassAdmit() == false) {
        this.$bvToast.toast("Please enter class (admitted)", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckAdmitSec() == false) {
        this.$bvToast.toast("Please enter section (admitted)", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckClassCurrent() == false) {
        this.$bvToast.toast("Please enter class (current)", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckCurrentSec() == false) {
        this.$bvToast.toast("Please enter section (current)", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckFees() == false) {
        this.$bvToast.toast("Please enter the monthly fees", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.CheckValues() == false) {
        this.$bvToast.toast("Invalid custom fee entered", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.fphoneerror || this.mphoneerror || this.phoneerror) {
        this.$bvToast.toast("Invalid contact details entered", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else if (this.emerPhoneerror) {
        this.$bvToast.toast("Invalid emergency contact entered", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
        this.check = 1;
      } else {
        this.$bvModal.show("modal-login");

        if (
          (this.myObj.status.toLowerCase() == "left" ||
            this.myObj.status.toLowerCase() == "registered") &&
          (this.$route.params.readmit == true ||
            this.$route.params.readmit == "true")
        ) {
          this.myObj.status = "present";
        }
        if (this.myObj.date_of_admission == "") {
          // console.log("addm");
          this.myObj.date_of_admission = null;
        }
        if (this.myObj.date_of_readmission == "") {
          // console.log("readd");
          this.myObj.date_of_readmission = null;
        }
        if (this.myObj.date_of_registraion == "") {
          // console.log("dreg");
          this.myObj.date_of_registraion = null;
        }
        if (this.myObj.challan_due_date == "") {
          // console.log("cha");
          this.myObj.challan_due_date = null;
        }
        if (this.myObj.date_of_birth == "") {
          this.myObj.date_of_birth = null;
        }
        if (this.admObj.testDate == "") {
          this.admObj.testDate = null;
        }
        if (this.admObj.interviewDate == "") {
          this.admObj.interviewDate = null;
        }
        if (this.myObj.grNoLocal == "") {
          this.myObj.grNoLocal = null;
        }
        if (this.myObj.receiptNo) {
          this.myObj.receiptNo = parseInt(this.myObj.receiptNo);
        } else this.myObj.receiptNo = 0;

        this.myObj.grNo = this.myObj.grNo.toString();
        this.myObj.monthly_fee = parseInt(this.myObj.monthly_fee);
        this.myObj.roll_number = parseInt(this.myObj.roll_number);
        this.myObj.family_code = parseInt(this.myObj.family_code);
        this.myObj.monthly_income = parseInt(this.myObj.monthly_income);

        this.admObj.sID = parseInt(this.admObj.sID);
        let data = {
          student: this.myObj,
          customFee: this.customObj,
          subjectsEnrollnment: this.subjectData,
          admissionTestData: this.admObj,
          docs: this.docData,
        };

        let smsvalue = false;
        if (this.selected === "add") smsvalue = this.addingMsg;

        var status = await this.post({
          url:
            this.$store.state.domain +
            // "students/SaveStudent?db=" +
            // "students/SaveStudentWithData?db=" +
            "students/SaveStudentWithDocsData?db=" +
            this.$store.state.userData.db +
            "&sms=" +
            smsvalue,
          body: data,
          message: "Student saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
          savingStudent: true,
        });
        if (status) {
          const class_admitted = this.myObj.class_admitted;
          const cls_admitted = this.myObj.cls_admitted;
          const class_current = this.myObj.class_current;
          const cls_current = this.myObj.cls_current;
          const section_admitted = this.myObj.section_admitted;
          const section_current = this.myObj.section_current;

          this.myObj = {
            id: 0,
            grNo: null,
            family_code: null,
            name: null,
            father_name: null,
            gender: null,
            picture: null,
            date_of_birth: null,
            date_of_birth_words: null,
            date_of_admission: null,
            blood_group: null,
            nationality: null,
            religion: null,
            place_of_birth: null,
            mother_tongue: null,
            address_current: null,
            address_permanent: null,
            contact_student: null,
            contact_father: null,
            contact_mother: null,
            contact_home: null,
            contact_tutor: null,
            last_institute: null,
            last_class: null,
            last_session: null,
            last_result: null,
            last_note: null,
            class_admitted: class_admitted,
            cls_admitted: cls_admitted,
            class_current: class_current,
            cls_current: cls_current,
            section_admitted: section_admitted,
            section_current: section_current,
            roll_number: null,
            house: null,
            surname: null,
            rfid_number: null,
            bform_number: null,
            date_of_registraion: null,
            date_of_readmission: null,
            father_cnic: null,
            father_education: null,
            father_occupation: null,
            monthly_income: null,
            office_phone: null,
            office_address: null,
            father_email: null,
            father_employer: null,
            mother_name: null,
            mother_cnic: null,
            mother_education: null,
            mother_occupation: null,
            mother_office_phone: null,
            mother_office_address: null,
            mother_email: null,
            mother_employer: null,
            guardian_name: null,
            guardian_cnic: null,
            guardian_education: null,
            guardian_occupation: null,
            guardian_office_phone: null,
            guardian_office_address: null,
            guardian_email: null,
            guardian_employer: null,
            guardian_relationship: null,
            emergency_name: null,
            emergency_contact: null,
            emergency_relationship: null,
            physical_handicap: null,
            allergies: null,
            remarks: null,
            monthly_fee: 0,
            discount_percentage: 0,
            challan_due_date: null,
            docs: "",
            status: this.$store.state.userData.studentStatus,
            campusID: this.$store.state.userData.cId,
            testPayment: false,
            testPassed: false,
            testCompleted: false,
            sessionID: 0,
            currency: "Rs",
          };
          this.documents = [];
          this.cleanStates();
          this.$bvModal.hide("modal-login");
          this.check = 1;
          this.$refs.wizard.reset();
          // this.$router.push("/manage-student");
          this.$router.go(-1);
        } else {
          // this.$bvToast.toast("Something went wrong!", {
          //   title: "Error!",
          //   variant: "danger",
          //   solid: true,
          // });
          this.$bvModal.hide("modal-login");
          this.check = 1;
        }
      }
    },
    selectMoreFile() {
      //this.moreFiles = this.$refs.mfile.files;
      if (this.$refs.mfile.files.length !== 0) {
        this.moreloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.mfile.files.length; i++) {
          formdata.append("file", this.$refs.mfile.files[i]);
        }

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch("https://upload.appick.io", requestOptions)
          .then((response) => response.json()) //2
          .then((result) => {
            console.log();
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              this.moreloading = false;
              var fn = result.myresp[i].path;
              this.mydocs.push({
                path: fn,
                orginal: result.myresp[i].originalname,
              });
            }
            console.log(this.mydocs);
          })

          .catch((error) => console.log("error", error));
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.picture = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectlogo() {
      // console.log("Osama");
      var axios = require("axios");

      this.fileProfile = this.$refs.fileProfile.files[0];
      //console.log(this.fileProfile.name);

      //console.log(this.$refs.fileProfile.files[0], "!!!!!!!!!!" );
      //Upload cover
      if (this.$refs.fileProfile.files[0] !== "") {
        this.logoloading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.fileProfile.files[0]);
        //console.log(this.fileProfile);
        axios
          .post("https://upload.appick.io/profile-picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            // this.file = undefined;
            // var fn1 = e.data.myresp[0].path;
            var fn1 = e.data.file.path;
            // console.log(fn1);
            this.plus = fn1;
            this.myObj.picture = this.plus;
            // console.log(this.plus);
            // console.log(this.myObj.picture);
            this.logoloading = "loaded";

            //  console.log(this.loading);
          })
          .catch((err) => {
            this.logoloading = false;
            console.log(err);
          });
      }
    },
    cleanStates() {
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["fname"];
      elem.state = undefined;
      var elem = this.$refs["dob"];
      elem.state = undefined;
      var elem = this.$refs["grNo"];
      elem.state = undefined;
      var elem = this.$refs["monthly_fee"];
      elem.state = undefined;
      // var elem = this.$refs["class_admitted"];
      // elem.state = undefined;
      // var elem = this.$refs["section_admitted"];
      // elem.state = undefined;
      // var elem = this.$refs["class_current"];
      // elem.state = undefined;
      // var elem = this.$refs["section_current"];
      // elem.state = undefined;
      // this.admittedSections = [];
      // this.currentSections = [];
    },
    CheckDiscount() {
      var elem = this.$refs["discount_percentage"];
      if (this.myObj.discount_percentage == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAdmitSec() {
      // console.log(this.myObj.section_admitted);
      var elem = this.$refs["section_admitted"];
      if (this.myObj.section_admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCurrentSec() {
      // console.log(this.myObj.section_current);
      var elem = this.$refs["section_current"];
      if (this.myObj.section_current == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClassAdmit() {
      var elem = this.$refs["class_admitted"];
      if (this.myObj.class_admitted == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClassCurrent() {
      var elem = this.$refs["class_current"];
      if (this.myObj.class_current == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "" || this.myObj.name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "" || this.myObj.father_name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDob() {
      var elem = this.$refs["dob"];
      if (this.myObj.date_of_birth == "" || this.myObj.date_of_birth == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFContact(s, e) {
      // console.log(this.myObj.contact_father);
      if (this.myObj.contact_father != "") {
        if (e.valid) {
          this.fphoneerror = false;
          this.myObj.contact_father = e.number;
          // console.log(this.myObj.contact_father);
        } else {
          this.fphoneerror = true;
        }
      } else {
        this.fphoneerror = false;
      }
      // console.log(this.myObj.contact_father.length);
      // if (this.myObj.contact_father.length == 12) {
      //   this.fphoneerror = false;
      //   return true;
      // } else {
      //   this.fphoneerror = true;
      //   return false;
      // }
    },
    CheckMContact(s, e) {
      // console.log(this.myObj.contact_mother);
      if (this.myObj.contact_mother != "") {
        if (e.valid) {
          this.mphoneerror = false;
          this.myObj.contact_mother = e.number;
          // console.log(this.myObj.contact_mother);
        } else {
          this.mphoneerror = true;
        }
      } else {
        this.mphoneerror = false;
      }
      // console.log(this.myObj.contact_mother.length);
      // if (this.myObj.contact_mother.length == 12) {
      //   this.mphoneerror = false;
      //   return true;
      // } else {
      //   this.mphoneerror = true;
      //   return false;
      // }
    },
    CheckContact(s, e) {
      // console.log(this.myObj.contact_student);
      if (this.myObj.contact_student != "") {
        if (e.valid) {
          this.phoneerror = false;
          this.myObj.contact_student = e.number;
          // console.log(this.myObj.contact_student);
        } else {
          this.phoneerror = true;
        }
      } else {
        this.phoneerror = false;
      }
      // console.log(this.myObj.contact_student.length);
      // if (this.myObj.contact_student.length == 12) {
      //   this.phoneerror = false;
      //   return true;
      // } else {
      //   this.phoneerror = true;
      //   return false;
      // }
    },
    CheckCnic() {
      var elem = this.$refs["fCnic"];
      if (this.myObj.father_cnic == null || this.myObj.father_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckMCnic() {
      var elem = this.$refs["mCnic"];
      if (this.myObj.mother_cnic == null || this.myObj.mother_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmail() {
      var elem = this.$refs["fEmail"];
      if (this.myObj.father_email == null || this.myObj.father_email == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGCnic() {
      var elem = this.$refs["gCnic"];
      if (this.myObj.guardian_cnic == null || this.myObj.guardian_cnic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGEmail() {
      var elem = this.$refs["gEmail"];
      if (
        this.myObj.guardian_email == null ||
        this.myObj.guardian_email == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerName() {
      var elem = this.$refs["emerName"];
      if (
        this.myObj.emergency_name == null ||
        this.myObj.emergency_name == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerRelation() {
      var elem = this.$refs["emerRel"];
      if (
        this.myObj.emergency_relationship == null ||
        this.myObj.emergency_relationship == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerContact(s, e) {
      // console.log(e);
      if (this.myObj.emergency_contact != "") {
        // console.log(e);
        if (e.valid) {
          this.emerPhoneerror = false;
          this.myObj.emergency_contact = e.number;
          // console.log(this.myObj.emergency_contact);
        } else {
          this.emerPhoneerror = true;
        }
      } else {
        this.emerPhoneerror = false;
      }
      // if (this.myObj.emergency_contact.length == 12) {
      //   // console.log(this.myObj.emergency_contact.length);
      //   this.emerPhoneerror = false;
      //   return true;
      // } else {
      //   this.emerPhoneerror = true;
      //   return false;
      // }
    },
    CheckFees() {
      // console.log(this.myObj.monthly_fee);
      const regex = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
      var elem = this.$refs["monthly_fee"];
      if (regex.test(this.myObj.monthly_fee)) {
        if (this.myObj.monthly_fee < 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else {
        return (elem.state = false);
      }
    },
    CheckGrNo() {
      var elem = this.$refs["grNo"];
      if (this.myObj.grNo == null || this.myObj.grNo == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CalculateDiscount() {
      // var re = /^((100)|(\d{1,2}(.\d*)?))%$/
      if (
        this.myObj.discount_percentage < 0 ||
        this.myObj.discount_percentage > 100
      ) {
        this.myObj.discount_percentage = 0;
      } else {
        var percent = this.myObj.discount_percentage / 100;
        var discount = percent * this.myObj.monthly_fee;
        this.netAmount = this.myObj.monthly_fee - discount;
      }

      // console.log(netAmount);
    },
    FillSections(text) {
      //return console.log(this.classes);
      // console.log(this.myObj.class_admitted);

      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.class_admitted) {
          if (elem.sections.length != 0) {
            this.admittedSections = elem.sections;
          } else {
            // console.log("null");
            this.admittedSections = [];
          }
        }
      });
      if (text !== "filled") this.myObj.section_admitted = 0;
      this.setSameFields("class");
      // console.log("Thisis", this.admittedSections);
    },
    async FillSectionsCurrent(text) {
      //return console.log(this.classes);
      // console.log(this.myObj.class_current);

      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.class_current) {
          if (elem.sections.length != 0) {
            this.currentSections = elem.sections;
          } else {
            // console.log("null");
            this.currentSections = [];
          }
        }
      });
      if (text !== "filled") this.myObj.section_current = 0;
      // console.log("Thisis", this.currentSections);

      // api for monthly fee acc to class
      if (
        text == "select" &&
        this.selected === "add" &&
        (this.myObj.monthly_fee == 0 || this.myObj.monthly_fee == "")
      ) {
        var obj = {
          url:
            this.$store.state.domain +
            "Students/LoadClassFee?clsID=" +
            this.myObj.class_current +
            "&campusId=" +
            this.myObj.campusID +
            "&db=" +
            this.$store.state.userData.db,
          token: this.$store.state.userData.token,
        };
        this.myObj.monthly_fee = await this.get(obj);
      }
      if (
        this.myObj.class_current > 0 &&
        this.disSetting &&
        this.disSetting.valueBool
      ) {
        var obj2 = {
          url:
            this.$store.state.domain +
            "Students/LoadClassFee?clsID=" +
            this.myObj.class_current +
            "&campusId=" +
            this.myObj.campusID +
            "&db=" +
            this.$store.state.userData.db,
          token: this.$store.state.userData.token,
        };
        this.myObj.classFee = await this.get(obj2);
      }
    },
    async LoadAreas() {
      var obj = {
        url:
          this.$store.state.domain +
          "StudentArea?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.areaOptions = await this.get(obj);
      // console.log(this.areaOptions);
    },
    async LoadStudent() {
      var obj = {
        url:
          // "Students/LoadStudentData?db=" +
          this.$store.state.domain +
          "Students/LoadStudentDataWithDocs?db=" +
          this.$store.state.userData.db +
          "&sID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };

      let result = await this.get(obj);
      this.myObj = result.student;
      if (this.myObj.currency == null) this.myObj.currency = "Rs";

      if (this.myObj.receiptNo > 0) {
        this.myObj.receiptNo = this.myObj.receiptNo.toString().padStart(4, "0");
      }
      // console.log("obj:", this.myObj);
      this.customObj = result.customFee;

      if (!this.customObj || this.customObj.length == 0) {
        this.customObj = [
          {
            id: 0,
            sID: this.$route.params.id,
            feeTypeID: 0,
            amount: 0,
            campusID: this.$store.state.userData.cId,
          },
        ];
      } else this.changeOptions();
      // console.log(this.customObj);

      if (!result.admissionTestData) {
        this.admObj = {
          id: 0,
          sID: this.$route.params.id,
          campusID: this.$store.state.userData.cId,
          testDate: null,
          testStatus: "",
          interviewDate: null,
          interviewStatus: "",
          remarks: "",
          documents: "",
        };
      } else {
        this.admObj = result.admissionTestData;
      }

      // console.log(result.docs);
      if (!result.docs) {
        this.docData = [];
      } else {
        this.docData = result.docs;
      }

      this.subjectData = result.subjectsEnrollnment;
      await this.loadSubjects();
      if (!this.subjectData || this.subjectData.length == 0) {
        this.setSubs();
      } else {
        let ids = this.subjectData.map((el) => el.subID);
        let data = this.allSubjects.reduce((acc, el) => {
          if (!ids.includes(el.id)) {
            acc.push({
              id: 0,
              sID: this.$route.params.id,
              subID: el.id,
              subName: el.subject,
              isExcluded: false,
              campusID: this.$store.state.userData.cId,
            });
          }
          return acc;
        }, []);
        this.subjectData = [...this.subjectData, ...data];
        // console.log(data, this.subjectData);
      }

      if (this.myObj.picture !== null && this.myObj.picture !== "") {
        this.plus = this.myObj.picture;
        this.logoloading = "loaded";
      }
      if (this.myObj.docs !== null && this.myObj.docs !== "") {
        this.documents = this.myObj.docs.split(",");
        // console.log(this.documents);
      }
      if (
        !["left", "enrolled", "alumni"].includes(
          this.myObj.status.toLowerCase()
        )
      ) {
        this.$store.commit("stdStatus", this.myObj.status.toLowerCase());
      }
      this.LoadData(this.myObj.campusID);
    },
    LoadData(id) {
      this.dataloading = true;
      let cID = this.$store.state.userData.cId;
      if (id) cID = id;
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          cID +
          "&dID=0",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response.data.data);
          this.classes = [];
          this.classes = response.data.data;
          this.classesOptions = [];
          this.classes.forEach((el) => this.classesOptions.push(el.cls));
          this.FillSections("filled");
          this.FillSectionsCurrent("filled");
          this.dataloading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.dataloading = false;
        });
    },
    async LoadInit() {
      var obj = {
        url:
          this.$store.state.domain +
          "Students/AddInit?campusId=" +
          this.$store.state.userData.cId +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      let initData = await this.get(obj);
      // console.log("data:", initData);
      this.myObj.grNo = initData.newG;
      this.myObj.family_code = initData.fCode;
    },
    async LoadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["family_update", "receipt_number"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.fObj = res.find((el) => el.key == "family_update");
      if (this.reportDomain != "myskoolhpgs") {
        this.spNo = res.find((el) => el.key == "receipt_number");
      }
      // console.log(this.fObj);
    },
    async LoadSettingsAll() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettingsAll?db=" +
          this.$store.state.userData.db,
        body: ["local_gr", "other_fields", "discount_ref", "receipt_number"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.schGr = res.find((el) => el.key == "local_gr");
      this.otherObj = res.find((el) => el.key == "other_fields");
      this.disSetting = res.find((el) => el.key == "discount_ref");
      if (this.disSetting && this.disSetting.valueBool) this.LoadDis("filled");
      if (this.reportDomain == "myskoolhpgs") {
        this.spNo = res.find((el) => el.key == "receipt_number");
      }
    },
    async formSubmitted() {
      this.CheckFContact();
      this.CheckMContact();
      this.CheckContact();
      if (
        this.CheckFContact() == false ||
        this.CheckMContact() == false ||
        this.CheckContact() == false
      ) {
        console.log("invalid");
        return this.$bvToast.toast("Please enter all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        console.log("valid");
        this.myObj.monthly_fee = parseInt(this.myObj.monthly_fee);
        this.myObj.roll_number = parseInt(this.myObj.roll_number);
        // if (this.documents.length != 0) {
        //   let formData = new FormData();
        //   this.documents.forEach((el) => {
        //     formData.append("file", el);
        //   });
        //   var axios = require("axios");
        //   axios
        //     .post("https://upload.appick.io", formData, {
        //       headers: {
        //         "Content-Type": "multipart/form-data",
        //       },
        //     })
        //     .then((e) => {
        //       console.log(e.data);
        //       let resp = e.data.myresp
        //       resp.forEach(doc => {
        //         this.myObj.docs += doc.path + ","
        //       })
        //     });
        // }
        if (this.myObj.id == 0) {
          //Add
          console.log("!!!!!!!!!!!!!", this.myObj);
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "students?db=" +
              this.$store.state.userData.db,
            // +
            // "&cId=" +
            // this.$store.state.userData.cId,
            body: this.myObj,
            message: "Student added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          if (status == true) {
            this.$router.push("/manage-student");
          }
        } else {
          //Edit
          // this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "students/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Student updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          // this.request = false;
          if (status) {
            this.$router.push("/manage-student");
          }
        }
      }
    },
  },
};
</script>
<style>
.wizard-tab-content {
  padding-top: 10px !important;
}
.heading {
  padding: 0;
  margin: 2.171303074670571vw 0;
  /* text-shadow: -4px 0 5px white, 0 4px 5px white, 4px 0 5px white,
    0 -4px 5px white; */
}
.input-form {
  margin: 0 70px;
  background: #fff;
}
#fileInput .custom-file-label {
  color: #707070;
  border: 1px dashed #33312f;
  text-align: center;
  padding: 2.5rem 1.5rem;
  width: 100%;
}
#fileInput input {
  display: none;
}
#imgInput input {
  display: none;
}
#imgInput {
  /* height: 8rem; */
  width: 50%;
}
#imgInput div {
  height: 8vw;
}
#imgInput .custom-file-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.type {
  background-color: red;
  border-radius: 10px;
  font-size: 0.8rem;
  padding: 10px;
  color: white;
}
.filename {
  padding-left: 25px;
}
.delete {
  margin: 0 0 0 auto;
  color: red;
}
#checkbox-1 {
  margin-right: 10px !important;
}
.submit {
  background-color: #00c192;
  color: #fff;
  width: 16.5vw;
  height: 4vw;
  font-weight: bold;
  border-color: #00c192;
}
.form-file-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px;
}
/* #prop-det, #prop-det div {
  height: 100%;
} */

.item-group {
  width: 50%;
  /* margin-top: 2.269399707174231vw; */
}
.inputfields,
.inputfields:focus {
  border: 1px solid #33312f;
  border-radius: 0.5856515373352855vw;
  height: 2.562225475841874vw;
  outline: none !important;
  resize: none;
}
textarea.form-control {
  height: 8vw !important;
}
@media only screen and (max-width: 990px) {
  .mob-setting {
    padding: 0 1rem !important;
    width: 100vw !important;
    margin: 0 auto !important;
  }
  .mobile-view {
    margin: 0 !important;
  }
  /* .col-form-label,
  .form-file-text,
  .custom-control-label {
    font-size: 3vw;
  } */
  .item-group {
    margin-top: 0;
  }
  .margin-top-mobile {
    margin-top: 5vw;
  }
  /* .col-form-label {
    display: none;
  } */
  .form-input-mobile-text {
    width: 92vw;
    margin: 10px auto;
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    font-size: 5.171303074670571vw;
    background-color: #f2f2f2 !important;
    border: 1px solid #33312f;
  }
  textarea.form-control {
    height: 12.781844802342606vw !important;
  }
  .form-input-mobile-text:focus {
    background-color: #00c192 !important;
    color: #fff !important;
  }
  .form-input-mobile-text::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(190, 190, 190);
    opacity: 1; /* Firefox */
    font-family: DINNextRoundedLTW01-Regular;
  }

  .form-input-mobile-text:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text:focus::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
  }
  .form-file-input-mobile {
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #fff !important;
  }
  .custom-file-label {
    border-radius: 2.8301610541727673vw !important;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #000 !important;
    height: 40vw;
    border: 1px solid #33312f;
  }
  .submit {
    width: 50vw;
    height: 10vw;
  }
  .filename {
    font-size: 3vw;
  }
  .shadowHead {
    font-size: 8vw !important;
  }
  #imgInput div {
    height: 29vw !important;
  }
  .item-group {
    height: 30vw !important;
  }
}

@media only screen and (min-width: 1366px) {
  .submit {
    width: 15.5vw;
    height: 7.8vh;
  }
}
@media only screen and (min-width: 1440px) {
  .submit {
    width: 14.7vw;
    height: 6.7vh;
  }
}
@media only screen and (min-width: 1536px) {
  .submit {
    width: 13.8vw;
    height: 6.9vh;
  }
}
@media only screen and (min-width: 1920px) {
  .submit {
    width: 11vw;
    height: 5.5vh;
  }
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
</style>
<style scoped>
.rcorners2 {
  border-radius: 100px;
  border: 4px solid var(--primary);
  padding: 2px;
}
.sticky {
  position: fixed;
  /* top: 0; */
  right: 2rem;
  /* margin-bottom: 30px; */
  /* position: fixed;
    bottom: 5%;
    z-index: 1031;  */
}
.file-uploader {
  position: relative;
  border: 1px solid #000;
  width: 100%;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;
  font-family: "Helvetica", sans-serif;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.file_upload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.file {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.file-name {
  font-family: "Helvetica", sans-serif;
  margin: 0px;
}

.file-delete i {
  color: #e62e2d;
  font-size: 2rem;
  cursor: pointer;
}
.modal-header {
  display: none;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
